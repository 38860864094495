import React from "react";
import {withStyles} from "@material-ui/core";
import {compose} from "redux";
import propTypes from "prop-types"

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = {
    childrenTab : {
        display: 'flex',
        width: "100%",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
};

class TabItems extends React.Component{

    render() {
        let {keys,value,classes,icon,text,image} = this.props;

        return (
            <React.Fragment>
                <div className={classes.childrenTab}>
                    <div className={"display_row align-center"}>
                        <span className={"mgr10"}>{icon}</span>
                        {image}
                        <div style={{fontSize: '12px'}}>
                            {text}
                        </div>
                    </div>
                    {keys === value &&
                    <div style={{display: 'flex'}}>
                        <ArrowForwardIosIcon style={{width: '8px', height: '8px'}}/>
                    </div>}
                </div>
            </React.Fragment>
        );
    }

}

TabItems.propType = {
    keys : propTypes.number,
    icon: propTypes.node,
    text: propTypes.string
};

export default compose(withStyles(styles))(TabItems)
