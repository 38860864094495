/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/30/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as historyActions from "../../../../redux/history/history_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import withStyles     from "@material-ui/core/styles/withStyles";
import ActionToolbar  from "../../../common/toolbar/action-toolbar";
import { Column }     from "devextreme-react/data-grid";
import GridContainer  from "../../../grid-container/grid-container";
import Divider        from "@material-ui/core/Divider";
import Icons          from "../../../common/icons";
import W00F0100Nav    from "./W00F0100Nav";
import Status         from "../../../common/status/status";
import _              from "lodash";
import W00F0100Detail from "./W00F0100Detail";
import moment         from "moment";

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    hide: {
        display: "none",
    },
    viewDetail: {
        cursor: "pointer",
    },
    panel: {
        transition: "all .4s",
    },
    panelForm: {
        transition: "all .5s",
        opacity: 1,
    },
    hiddenOpacity: {
        opacity: 0,
    },
    leftPanelMinimum: {
        width: "105px",
    },
    rightPanelMinimum: {
        width: "calc(100% - 105px)",
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + " !important",
        "& svg": {
            "& path": {
                fill: theme.palette.primary.contrastText,
            },
        },
    },
    textError: {
        "& .MuiFormHelperText-root.Mui-error": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100px",
        },
    },
    toolTipTitle: {
        "& .MuiTooltip-popper": {
            display: "none",
        },
    },
});
class W00F0100 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: {
                rows: [],
                total: 0,
            },
            showDrawer: true,
            activeIndex: null,
            dataModule: []
        };
        this.changePage = false;
        this.filter = {
            limit: 15,
            skip: 0,
        };
        this.params = {};
        this.onClicking = false;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    loadHistorySearch = () => {
        if (this.params) {
            this.params.skip = this.filter.skip;
            this.params.limit = this.filter.limit;
            this.setLoading("History", true);
            this.props.historyActions.historySearch(this.params, (error, data) => {
                this.setLoading("History");
                if (error) {
                    let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    this.setState({ dataSource: data });
                }
            });
        }
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadHistorySearch();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadHistorySearch();
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value,
            },
        });
        return Object.keys(value).length !== 0;
    };

    handleClose = (key) => {
        if (key === "showPopupDetail") {
            this.setState({ activeIndex: null });
            this.onClicking = false;
        }
        this.setState({ [key]: false });
    };

    handleOpen = () => {
        this.setState({ showDrawer: true });
    };
    _renderAction = (e) => {
        const { data } = e.row;
        return <div>{this._renderStatus(data)}</div>;
    };
    _renderStatus = (data) => {
        const { classes } = this.props;
        let actions = {
            0: {
                color: "success",
                action: Config.lang("DHR_Them_moi1"),
                icons: "add",
            },
            1: {
                color: "info",
                action: Config.lang("DHR_Sua"),
                icons: "edit",
            },
            2: {
                color: "",
                action: Config.lang("DHR_Khac"),
                icons: "more_horizotnal",
            },
            3: {
                color: "warning",
                action: Config.lang("DHR_Xoa"),
                icons: "delete",
            },
        };
        if (!_.isEmpty(data)) {
            let status = {
                AppStatusName: actions[data.action].action,
                AppStatusColor: actions[data.action].color,
                AppStatusIcon: (
                    <Icons
                        name={actions[data.action].icons}
                        color={actions[data.action].color}
                    />
                ),
            };
            return <Status data={status} className={classes.statusText} />;
        }
    };
    _renderContent = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        if (Config.isEmpty(data.content) || _.isArray(data.content)) return false;
        let titles = Object.keys(data.content).map((item) => {
            if (!(item.substring(0, 4) === "data")) {
                if (
                    _.isObject(data.content[item]) &&
                    !_.isUndefined(data.content[item]["old"])
                ) {
                    return {
                        title: Config.lang(item),
                        value: `${data.content[item]["old"]} -> ${data.content[item]["new"]}`,
                    };
                } else {
                    return {
                        title: Config.lang(item),
                        value: data.content[item],
                    };
                }
            } else {
                return null;
            }
        });
        return (
            <div>
                {titles.map((i, idx) => {
                    if (!_.isNull(i) && !_.isObject(i.value)) {
                        let parseDate = moment(i.value, moment.ISO_8601, true).isValid() ? Date.parse(i.value) : false;
                        return (
                            <div className="display_row align-center" key={idx}>
                                {`${i.title}: ${parseDate ? Config.convertDate(parseDate) : i.value}`}
                            </div>
                        );
                    } else if (!_.isNull(i)) {
                        return (
                            <div
                                className={classes.viewDetail + " display_row align-center"}
                                onClick={() => this.onOpenPopupDetail(e, i)}
                                key={idx}
                                id={`rowIndex${e.rowIndex}`}
                                style={{
                                    color:
                                        e.rowIndex === this.state.activeIndex
                                            ? "#111D5E"
                                            : "#7F828E",
                                }}
                                onMouseEnter={() => {
                                    if (!this.onClicking) {
                                        document.getElementById(
                                            `rowIndex${e.rowIndex}`
                                        ).style.color = "#111D5E";
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (!this.onClicking) {
                                        document.getElementById(
                                            `rowIndex${e.rowIndex}`
                                        ).style.color = "#7F828E";
                                    }
                                }}
                            >
                                <div>{`${i.title}:`}</div>
                                <div className=" display_row align-center pdl5">
                                    <span>{Config.lang("DHR_Chi_tiet")}</span>
                                    {/* <Icons  name={"arrowhead_right"} colorHover={onHover ? "primary" : ""} /> */}
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.99919 8.00011C7.99919 8.15145 7.94786 8.30345 7.84452 8.42678L4.51119 12.4268C4.27519 12.7094 3.85519 12.7474 3.57252 12.5121C3.28919 12.2761 3.25186 11.8568 3.48719 11.5734L6.47052 7.99278L3.59452 4.41811C3.36386 4.13145 3.40919 3.71078 3.69586 3.48078C3.98252 3.24945 4.40186 3.29545 4.63319 3.58211L7.85186 7.58211C7.94986 7.70411 7.99919 7.85211 7.99919 8.00011ZM11.1375 7.99271L8.26086 4.41805C8.03086 4.13138 8.07619 3.71071 8.36219 3.48071C8.64952 3.25005 9.06886 3.29538 9.30019 3.58205L12.5189 7.58205C12.6169 7.70405 12.6655 7.85205 12.6655 8.00005C12.6655 8.15205 12.6142 8.30338 12.5109 8.42671L9.17752 12.4267C8.94219 12.71 8.52219 12.748 8.23886 12.512C7.95619 12.2767 7.91752 11.8567 8.15352 11.5734L11.1375 7.99271Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };
    onOpenPopupDetail = (e, item) => {
        if (Config.isEmpty(this.state.activeIndex)) {
            this.setState({
                showDrawer: false,
                showPopupDetail: true,
                dataDetail: item,
                activeIndex: e.rowIndex,
            });
        }
        this.onClicking = true;
    };
    _renderModule = (e) => {
        const { data } = e.row;
        const { dataModule } = this.state;
        const findData = !Config.isEmpty(dataModule) && data.moduleID ? dataModule.find(item => item.ModuleID === data.moduleID) : "";
        return (
            <div>
                {findData ? findData.ModuleName : ""}
            </div>
        )
    }
    render() {
        const { classes } = this.props;
        const {
            loadingHistory,
            dataSource,
            showDrawer,
            showPopupDetail,
            dataDetail,
        } = this.state;
        return (
            <React.Fragment>
                <W00F0100Detail
                    showPopupDetail={showPopupDetail}
                    dataDetail={dataDetail}
                    handleClose={() => {
                        this.handleClose("showPopupDetail");
                    }}
                />
                <ActionToolbar
                    alignment={"flex-end"}
                    title={Config.lang("DHR_Lich_su_tac_dong")}
                    showBorder={false}
                    upperCase={false}
                    className={"text-uppercase"}
                />
                <Divider style={{ marginLeft: -15, marginRight: -15 }} />
                {/* className="display_row row" style={{ flexDirection: "row" }} */}
                <div style={window.innerWidth > 768 ? { marginLeft: -15 } : {}}>
                    <Row>
                        <Col
                            style={
                                window.innerWidth < 768
                                    ? {
                                        marginLeft: 0,
                                        padding: 0,
                                    }
                                    : { paddingRight: 0 }
                            }
                            xs={12}
                            sm={4}
                            className={
                                classes.panel +
                                " " +
                                (!showDrawer && window.innerWidth > 768
                                    ? classes.leftPanelMinimum
                                    : "")
                            }
                        >
                            <div className={classes.panelForm}>
                                <W00F0100Nav
                                    showDrawer={showDrawer}
                                    FormID={"W00F0100"}
                                    handleOpen={this.handleOpen}
                                    handleClose={() => {
                                        this.handleClose("showDrawer");
                                    }}
                                    params={(source) => {
                                        this.params = source;
                                        this.loadHistorySearch();
                                    }}
                                    getCboModule={(data) => { this.setState({ dataModule: data }); }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            sm={8}
                            className={
                                classes.panel +
                                " " +
                                (window.innerWidth > 768
                                    ? "bdl " + (!showDrawer ? classes.rightPanelMinimum : "")
                                    : "")
                            }
                        >
                            <FormGroup style={{ marginTop: 20 }}>
                                <GridContainer
                                    totalItems={dataSource && dataSource.total}
                                    itemPerPage={this.filter.limit}
                                    skipPerPage={this.filter.skip}
                                    listPerPage={[15, 30, 45, 60]}
                                    dataSource={dataSource && dataSource.rows}
                                    keyExpr={"id"}
                                    showBorders={false}
                                    height={Config.getHeightGrid() - 20}
                                    onChangePage={this.onChangePage}
                                    onChangePerPage={this.onChangePerPage}
                                    loading={loadingHistory}
                                    rowAlternationEnabled={false}
                                    typePaging={"remote"}
                                    hoverStateEnabled={true}
                                // showRowLines={false}
                                >
                                    <Column
                                        caption={Config.lang("DHR_Hanh_dong")}
                                        dataField={"action"}
                                        alignment={"left"}
                                        width={150}
                                        cellRender={this._renderAction}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Nhan_vien")}
                                        dataField={"userID"}
                                        alignment={"left"}
                                        minWidth={300}
                                        cellRender={(e) => {
                                            const { data } = e.row;
                                            // const employee = Config.getUser({ UserID: data.userID });
                                            return (
                                                <div>
                                                    <span>{`${data.userID} - ${data.userName}`}</span>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Nghiep_vu")}
                                        dataField={"transactionName"}
                                        alignment={"left"}
                                        width={250}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Ngay")}
                                        dataField={"createdAt"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                        alignment={"center"}
                                        width={100}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Mo_ta")}
                                        dataField={"content"}
                                        alignment={"left"}
                                        minWidth={250}
                                        cellRender={this._renderContent}
                                    />
                                    <Column
                                        caption={"Module"}
                                        alignment={"left"}
                                        minWidth={150}
                                        cellRender={this._renderModule}
                                    />
                                </GridContainer>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default compose(
    connect(
        (state) => ({
            historySearch: state.history.historySearch,
        }),
        (dispatch) => ({
            historyActions: bindActionCreators(historyActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles)
)(W00F0100);