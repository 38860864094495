/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/23/2019
 * @Example
 */

import React, {useEffect, useRef, useState} from "react";
import {FormGroup, Row, Col}                from "react-bootstrap";
import W00F0010ListRoles                    from "./W00F0010ListRoles";
import W00F0010RoleInfo                     from "./W00F0010RoleInfo";
import ActionToolbar                        from "../../../common/toolbar/action-toolbar";
import {connect}                            from "react-redux";
import {bindActionCreators}                 from "redux";
import * as mainActions                     from "../../../../redux/main/main_actions";
import {TextField}                          from "@material-ui/core";
import Config                    from "../../../../config";
import * as generalActions  from "../../../../redux/general/general_actions";
import Filter               from "../../../filter/filter";
import {Combo}              from "../../../common/form-material";
import ButtonGeneral        from "../../../common/button/button-general";
import * as w00f0010Actions from "../../../../redux/W0X/W00F0010/W00F0010_actions";

const W00F0010 = (props) => {
    const _initDataSouce = {
        rows: [],
        total: 0
    };
    const _initFilter = {
        strSearch: "",
        limit: 20,
        skip: 0
    };
    const [iPermission, setPermission] = useState(0);
    const [RoleID, setRoleID] = useState("");
    const [loading, _setLoading] = useState({main: false, cboScreen: false, cboUser: false});
    const [dataCboListUsers, setDataCboListUsers] = useState( _initDataSouce);
    const [dataCboListScreens, setDataCboListScreens] = useState( _initDataSouce);
    const [ScreenID, setScreenID] = useState( "");
    const [UserID, setUserID] = useState( "");
    const [isSpecifiedData, setIsSpecifiedData] = useState( "");
    const [isManager, setIsManager] = useState( "");

    const filter = useRef({isSpecifiedData: "", isManager: ""});
    const filterUser = useRef({ScreenID: "", _initFilter});
    const filterScreen = useRef({UserID: "", _initFilter});
    const listRoles = useRef(null);
    const roleInfo = useRef(null);
    const cboSpecifiedData = [
        {
            id: 0,
            name: Config.lang("DHR_Co")
        },
        {
            id: 1,
            name: Config.lang("DHR_Khong")
        },
        {
            id: 2,
            name: Config.lang("DHR_Tat_ca")
        }
    ];
    const cboManager = [
        {
            id: 0,
            name: Config.lang("DHR_Co")
        },
        {
            id: 1,
            name: Config.lang("DHR_Khong")
        },
        {
            id: 2,
            name: Config.lang("DHR_Tat_ca")
        }
    ];

    const setLoading = (obj) => {
        _setLoading({...loading, ...obj});
    };

    const onSelect = (RoleID) => {
        setRoleID(RoleID);
    };

    const loadCboListUser = (isReset) => {
        const {strSearch, limit, skip} = filterUser.current || {};
        const param = {
            SearchValue: strSearch || "",
            limit: limit,
            skip: skip
        };
        setLoading({cboListUser: true});
        props.w00f0010Actions.getListUser(param, (error, data) => {
            setLoading({cboListUser: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if(data) {
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboListUsers({
                    rows: isReset ? rows : dataCboListUsers.rows.concat(rows),
                    total: total
                });
            }

        });
    };


    const loadCboListScreen = (isReset) => {
        const {strSearch, limit, skip} = filterScreen.current || {};
        const param = {
            SearchValue: strSearch || "",
            limit: limit,
            skip: skip
        };
        setLoading({cboListScreen: true});
        props.w00f0010Actions.getListScreen(param, (error, data) => {
            setLoading({cboListScreen: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if(data) {
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboListScreens({
                    rows: isReset ? rows : dataCboListScreens.rows.concat(rows),
                    total: total
                });
            }

        });
    };

    useEffect(() => {
        const loadPermission = async () => {
            await props.generalActions.getPermission("W00F0010", (isPer) => {
                setPermission(isPer);
            });
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (iPermission) {
            loadCboListUser();
            loadCboListScreen();

            Config.controller.setFilter(renderFilter());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const renderFilter = () => {
        return (
            <TextField
                style={{margin: 0, maxWidth: 580, }}
                variant={'outlined'}
                fullWidth={true}
            />
        );
    };

    const filterChange = (key,e) => {
        if(!key) return false;
        switch (key) {
            case "ScreenID":
                setScreenID(e.value ? e.value.ScreenID : "");
                filterScreen.current.ScreenID = e.value ? e.value.ScreenID : "";
                break;
            case "UserID":
                setUserID(e.value ? e.value.UserID : "");
                filterUser.current.UserID = e.value ? e.value.UserID : "";
                break;
            case "isSpecifiedData":
                setIsSpecifiedData(e.value === 0 ? 0 : (e.value === 2 ? null : (e.value || "")));
                filter.current.isSpecifiedData = e.value;
                break;
            case "isManager":
                setIsManager(e.value === 0 ? 0 : (e.value === 2 ? null : (e.value || "")));
                filter.current.isManager = e.value;
                break;
            default:
                break;
        }
    };

    const renderFilterData = () => {
        const { isSpecifiedData, isManager} = filter.current || {};
        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboListUsers}
                                            skip={filterUser.current.skip}
                                            limit={filterUser.current.limit}
                                            displayExpr={'UserName'}
                                            keyExpr={'UserID'}
                                            valueExpr={'UserID'}
                                            value={filterUser.current.UserID}
                                            loading={loading.cboListUser}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Nguoi_dung')}
                                            showClearButton={true}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.UserID + ' - ' + data.UserName;
                                            }}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => filterChange("UserID", e)}
                                            onInputChanged={(e) => {
                                                filterUser.current.strSearch = e.target.value;
                                                filterUser.current.skip = 0;
                                                loadCboListUser(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterUser.current.skip = e.skip;
                                                filterUser.current.limit = e.limit;
                                                loadCboListUser();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboListScreens}
                                            skip={filterScreen.current.skip}
                                            limit={filterScreen.current.limit}
                                            displayExpr={'ScreenName'}
                                            keyExpr={'ScreenID'}
                                            valueExpr={'ScreenID'}
                                            value={filterScreen.current.ScreenID}
                                            loading={loading.cboListScreen}
                                            stylingMode={'outlined'}
                                            label={Config.lang('DHR_Man_hinh')}
                                            showClearButton={true}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.ScreenID + ' - ' + data.ScreenName;
                                            }}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => filterChange("ScreenID", e)}
                                            onInputChanged={(e) => {
                                                filterScreen.current.strSearch = e.target.value;
                                                filterScreen.current.skip = 0;
                                                loadCboListScreen(true);
                                            }}
                                            onLoadMore={(e) => {
                                                filterScreen.current.skip = e.skip;
                                                filterScreen.current.limit = e.limit;
                                                loadCboListScreen();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Combo
                                    dataSource={cboSpecifiedData}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    margin={"dense"}
                                    value={isSpecifiedData || ""}
                                    stylingMode={'outlined'}
                                    showClearButton={true}
                                    label={Config.lang('DHR_Du_lieu_dac_biet')}
                                    onValueChanged={(e) => filterChange("isSpecifiedData", e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Combo
                                    dataSource={cboManager}
                                    displayExpr={'name'}
                                    valueExpr={'id'}
                                    margin={"dense"}
                                    value={isManager || ""}
                                    stylingMode={'outlined'}
                                    showClearButton={true}
                                    label={Config.lang('DHR_Quan_ly')}
                                    onValueChanged={(e) => filterChange("isManager", e)}
                                />
                            </FormGroup>
                            <div style={{display: "flex", justifyContent: "center",}}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={listRoles.loadGridListRoles}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const reloadGrid = () => {
        roleInfo.current.loadGridPermissionInfo();
    };

    if (!iPermission) return null;
    return (
        <div>
            <div className={"hidden"}>{renderFilterData()}</div>
            <FormGroup>
                <ActionToolbar
                    title={Config.lang("DHR_Phan_quyen")}
                />
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={3} lg={3} className={"no-pdr"}>
                        <W00F0010ListRoles
                            ref={listRoles}
                            filterData={{
                                IsSpecifiedData: isSpecifiedData,
                                IsManager:       isManager,
                                SearchUserID:    UserID,
                                ScreenID
                            }}
                            reloadGrid={reloadGrid}
                            onSelect={onSelect}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <W00F0010RoleInfo listRolesRef={listRoles} roleID={RoleID} ref={roleInfo}/>
                    </Col>
                </Row>
            </FormGroup>
        </div>
    );
}

export default connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch)
    }), null, {forwardRef: true}
)(W00F0010);
