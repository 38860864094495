import React from "react";
import {withStyles} from "@material-ui/core";
import Config from '../../../../config';
import propTypes from "prop-types"
import Attachments from "../../../common/attachments/attachments";

const styles = {
    legend: {
        fontSize: 15,
        fontWeight: 600,
        marginTop: 24
    }
};

class W09F2000Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: []
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data) {
                    let _data = [];
                    data && data.rows && data.rows.forEach(d => {
                        const blob = new Blob([new Uint8Array(d.Content.data)]);
                        d.URL = URL.createObjectURL(blob);
                        d.FileExt = d.FileExt.toLowerCase();
                        _data.push(d);
                    });
                    this.setState({attachments: _data});
                }
            });
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    render() {
        const {attachments} = this.state;

        return (
            <div style={{width: "100%"}}>
                <Attachments
                    error={false}
                    ref={ref => this.attRef = ref}
                    showButton={true}
                    files={attachments ? attachments : []}
                    disabled={true}
                    isLocal={true}
                />
            </div>
        );
    }

}

W09F2000Attachments.propTypes = {
    data: propTypes.any,
};

export default withStyles(styles)(W09F2000Attachments);
