import React from "react";
import {withStyles} from "@material-ui/core";
import {Col, FormGroup, Row} from 'react-bootstrap';
import Config from '../../../../config';
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import propTypes from "prop-types"
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    legend: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 24,
        marginBottom: 12,
        border: "none"
    },
    iconBlock: {
        width: "20%",
        textAlign: "center"
    },
    iconLeave: {
        margin: 'auto',
        marginBottom: '7px',
        width: theme.spacing(7),
        height: theme.spacing(7),
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '600',
        cursor: "pointer"
    },
    iconInfo: {
        right: 6,
        width: 12,
        bottom: 7,
        height: 12,
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '50%',
        color: 'black',
        fontSize: 10,
        lineHeight: '12px'
    },
    tooltip: {
        fontSize: 12
    },
    danger: {
        backgroundColor: theme.palette.danger.main
    },
    success: {
        backgroundColor: theme.palette.success.main
    },
    info: {
        backgroundColor: theme.palette.info.main
    }
});

class W09F2000Leave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaveAmount: null,
            dataLeaveInfo: {
                rows: [],
                total: 0
            }
        };
        this.filter = {
            skip: 0,
            limit: 10,
        };
    }

    loadGrid = async (type = "") => {
        const {mode, onLoad} = this.props;
        const {skip, limit} = this.filter;
        if (onLoad) {
            await onLoad(mode, type, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (type === "LeaveAmount") {
                    this.setState({leaveAmount: data});
                } else {
                    this.setState({dataLeaveInfo: {rows: data && data.rows ? data.rows : [], total: data && data.total ? data.total : 0 }});
                }
            }, skip, limit, type === "LeaveAmount");
        }
    };

    componentDidMount = async () => {
        await this.loadGrid("LeaveAmount");
        this.loadGrid("LeaveInfo");
    };

    handleChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid("LeaveInfo");
    };

    handleChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid("LeaveInfo");
    };

    renderTooltip = () => {
        const {leaveAmount} = this.state;
        return <table>
            <tbody>
                <tr><td style={{width: 28}}>{((leaveAmount && leaveAmount.BacklogLeave) || 0)}</td><td>{Config.lang("DHR_Phep_ton")}</td></tr>
                <tr><td style={{width: 28}}>{((leaveAmount && leaveAmount.SeniorityLeave) || 0)}</td><td>{Config.lang("DHR_Phep_tham_nien")}</td></tr>
                <tr><td style={{width: 28}}>{((leaveAmount && leaveAmount.AnnualLeave) || 0)}</td><td>{Config.lang("DHR_Phep_nam")}</td></tr>
            </tbody>
        </table>;
    };

    render() {
        const {classes} = this.props;
        const {leaveAmount, dataLeaveInfo} = this.state;
        const amoutYear = leaveAmount ? (leaveAmount.BacklogLeave || 0)
            + (leaveAmount.SeniorityLeave || 0)
            + (leaveAmount.AnnualLeave || 0) : 0;
        const leaves = [
            {
                ID: "YearLeave",
                name: Config.lang("DHR_Phep_nam"),
                value: amoutYear || 0,
                color: "info"
            },
            {
                ID: "CompensationLeave",
                name: Config.lang("DHR_Phep_bu"),
                value: leaveAmount && leaveAmount.CompensationLeave,
                color: "info"
            },
            {
                ID: "UsedLeave",
                name: Config.lang("DHR_Phep_da_nghi"),
                value: leaveAmount && leaveAmount.UsedLeave,
                color: "danger"
            },
            {
                ID: "RemainLeave",
                name: Config.lang("DHR_Phep_con_lai"),
                value: leaveAmount && leaveAmount.RemainLeave,
                color: "success"
            }
        ];

        return (
            <div>
                <FormGroup>
                    <div className={"display_row align-center flex-wrap"}>
                        {leaves && leaves.map(l => {
                            return (
                                <div key={l.ID} className={classes.iconBlock}>
                                    <Avatar className={classes.iconLeave + " " + classes[l.color]}>
                                        {l.value || 0}
                                        {l.ID === "YearLeave" && <Tooltip classes={{tooltip: classes.tooltip}} title={this.renderTooltip()}>
                                            <span ref={ref => this.iconInfo = ref} className={classes.iconInfo}>i</span>
                                        </Tooltip>}
                                    </Avatar>
                                    {l.name || ""}
                                </div>
                            );
                        })}
                    </div>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                dataSource={dataLeaveInfo && dataLeaveInfo.rows ? dataLeaveInfo.rows : []}
                                totalItems={dataLeaveInfo && dataLeaveInfo.total ? dataLeaveInfo.total : 0}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                onChangePage={this.handleChangePage}
                                onChangePerPage={this.handleChangePerPage}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("DHR_Ngay_da_nghi_phep")}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    dataField={"LeaveDate"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Loai_phep")}
                                    dataField={"LeaveTypeName"}
                                />
                                <Column
                                    caption={Config.lang("DHR_So_luong")}
                                    dataField={"Quantity"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ly_do")}
                                    dataField={"Reason"}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        );
    }

}

W09F2000Leave.propTypes = {
    data: propTypes.any,
};

export default withStyles(styles, {withTheme: true})(W09F2000Leave)
