import React from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles, Tabs, Tab, Button, Icon, Hidden} from "@material-ui/core";
import {FormGroup, Row, Col} from "react-bootstrap";
import Config from "../../../../config";
import TabItems from "./W09F2000LabelItems";
import W09F2000Employees from "./W09F2000Employees";
import W09F2000Personal from "./W09F2000Personal";
import {connect} from "react-redux";
import * as W09f2000Actions from "../../../../redux/W0X/W09F2000/W09F2000_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import {browserHistory} from "react-router";
import * as generalActions from "../../../../redux/general/general_actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Icons from "../../../common/icons";
import W09F2000Family from "./W09F2000Family";
import W09F2000Contracts from "./W09F2000Contracts";
import W09F2000Leave from "./W09F2000Leave";
import W09F2000Attachments from "./W09F2000Attachments";
import UserImage from "../../../common/user/user-image";
import {LoadPanel, TextBox} from "devextreme-react";
import {Combo}              from "../../../common/form-material";
import ButtonGeneral        from "../../../common/button/button-general";
import W09F2210             from "../W09F2210/W09F2210";
import W09F2001HR from '../W09F2001/W09F2001HR';
import W09F2000Insurrance from "./W09F2000Insurrance";
import W09F2000HistorySocialInsurance from "./W09F2000HistorySocialInsurance";
import W09F2000Evaluation from "./W09F2000Evaluation";
import W09F2000TrainingHistory from "./W09F2000TrainingHistory";

const styles = (theme) => {
   return {
       root: {
           minWidth: '200px',
           width: '20%',
           height: '42px',
           float: 'left',
           paddingLeft: '-15px',
           "&:after": {
               content: "none"
           }
       },
       flexBox: {
           display: 'flex',
       },
       flexCol: {
           display: 'flex',
           flexDirection: 'column',
           backgroundColor: '#F9F9F9',
           marginLeft: '-15px',
           position: "fixed",
           top: 100,
           [theme.breakpoints.down('xs')]: {
               width: '40px',
               overflow:'hidden',
           },
       },
       toolbar: {
           position: 'fixed',
           zIndex: 5,
           background: '#fff',
           marginTop: 0,
           [theme.breakpoints.down('sm')]: {
               left: 1
           },
           width: "calc(100% - 100px)"
       },
       tab: {
           backgroundColor: '#F9F9F9',
           height: "989px"
       },
       selected: {
           backgroundColor: 'white',
           borderRight: '10px red bold'
       },
       wrapper: {
           flexDirection: "row",
           justifyContent: "start",
       },
       rootTab: {
           width: "240px",
           minHeight: '42px',
           textTransform: "uppercase"
       },
       iconStyle: {
           marginRight: '22px',
           width: '16px',
           height: '16px'
       },
       bgIndicator: {
           backgroundColor: "transparent",
       },
       tabContent : {
           padding: "0 15px",
           width: '70%',
           position: "relative",
           top: 0,
           left: 225,
           [theme.breakpoints.down('sm')]: {
               padding: "0px 15px",
               width: '100%',
               left: 20,
           },
       },
       collapseBoardContent: {
           marginBottom: 18,
           padding: '0 !important'
       },
       heading: {
           fontWeight: 500,
           fontSize: 18,
           textTransform: "uppercase"
       },
       collapseBoard: {
           boxShadow: 'initial !important',
           margin: '0 0 15px 0 !important',
           "&:before": {
               content: "none"
           }
       },
       ExpansionPanelSummary: {
           minHeight: 'initial !important',
           padding: "0 !important",
           width: "30%",
           color: theme.palette.primary.main,
           "& .MuiExpansionPanelSummary-content": {
               margin: "0 !important"
           },
           "&.Mui-expanded": {
               minHeight: "inherit"
           }
       },
       ExpansionPanelDetails: {
           padding: 15
       },
       textBox: {
            maxWidth: 140
        }
   };
};
const tabs = [
    {
        ID: 0,
        name: "Thong_tin_chung",
        icon: require("./icons/general.svg"),
        data: null,
    },
    {
        ID: 1,
        name: "Thong_tin_lien_he",
        icon: require("./icons/contact.svg"),
        data: null,
    },
    {
        ID: 2,
        name: "Thong_tin_gia_dinh",
        icon: require("./icons/family.svg"),
        data: null,
    },
    {
        ID: 3,
        name: "Hop_dong_lao_dong",
        icon: require("./icons/contracts.svg"),
    },
    {
        ID: 4,
        name: "Thong_tin_phep",
        icon: require("./icons/calendar.svg"),
        data: null,
    },
    {
        ID: 5,
        name: "Tai_lieu_dinh_kem",
        icon: require("./icons/attachment.svg"),
        data: null,
    },
    {
        ID: 6,
        name: "Lich_su_nhan_su",
        icon: require("./icons/hr.svg"),
        data: null,
    },
    {
        ID: 7,
        name: "Lich_su_BHXH",
        icon: require("./icons/socialInsurance.svg"),
        data: null,
    },
    {
        ID: 8,
        name: "Danh_gia",
        icon: require("./icons/evaluation.svg"),
        data: null,
    },
    {
        ID: 9,
        name: "Lich_su_dao_tao",
        icon: require("./icons/trainingHistory.svg"),
        data: null,
    },
    {
        ID: 10,
        name: "Ho_so_bao_hiem",
        icon: require("./icons/insurrance.svg"),
        data: null,
    },
];
class W09F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            activeTab: '1',
            selected: false,
            iPermission: [],
            infoEmployee: null,
            tab0: true,
            loading: null,
            loadingCboEmployee: false,
            dataCboEmployees: {
                rows: [],
                totals: 0
            },
            rowData: {},
            showW09F2210Popup: false
        };
        this.filterCboEmployees = {
            Employee: "",
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.scrollTop = 0;
        this.directScroll = "down";
        this.tabOpening = false;
        this.tabOpened = {
            tab0: true,
        };
        this.clickScroll = false;
        this.scrolling = false;
        this.ref = {};
        this.await = false;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F5700", (isPer) => {
            this.setState({iPermission: isPer});
        }, true);
    };

    loadInfo = async (mode, type, cb, skip,  limit, isAwait) => {
        let {EmployeeID} = this.getInfo();
        let params = {
            FormID: "W09F2000",
            EmployeeID : this.filterCboEmployees.Employee || EmployeeID,
            Language: Config.language || "84",
            Mode: mode || 0,
            Type: type || "",
            skip: skip || 0,
            limit: limit || 10,
        };
        this.await = !!isAwait;

        this.setState({loading: this.ref["tab" + (mode - 1)]});
        return new Promise((resolve => {
            this.props.W09f2000Actions.getInfo(params, (errors, data) => {
                this.setState({loading: null});
                if (errors) {
                    Config.popup.show('ERROR', errors);
                    return false;
                }
                if (mode === 1) {
                    this.setState({infoEmployee: data});
                }
                cb && cb(errors, data);
                return resolve(data);
            })
        }));
    };

    loadCboEmployees = (isReset) => {
        const {skip, limit, strSearch} = this.filterCboEmployees;
        const params = {
            HostID: "",
            FormID: "W09F2225",
            Language: Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch
        };

        this.setState({loadingCboEmployee: true});
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setState({loadingCboEmployee: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        // const permission = this.state.iPermission?.find(i => i.FormID === 'W09F2000')?.IsPermission ?? 0;
        // if (permission <= 0) return;
        this.loadCboEmployees();

        window.addEventListener('scroll', this.onScroll);
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScroll);
    };

    onScroll = () => {
        const direction = this.scrollTop < window.scrollY ? "down" : "up";
        if (this.ref && !this.clickScroll) {
            this.scrolling = true;
            const items = Object.keys(this.ref);
            for(let item of items) {
                if (this.ref[item]) {
                        const scrollY = direction === "down" ? window.innerHeight + (window.scrollY - 150) : window.innerHeight + window.scrollY + 200;
                        if (scrollY >= this.ref[item].offsetTop) {
                            if (direction === "up" && scrollY < this.ref[item].clientHeight + this.ref[item].offsetTop ) break;
                            if (!this.tabOpening) {
                                const ID = item.substring(3, item.length);
                                this.handleChange(null, Number(ID))
                                if (!this.tabOpened[item]) {
                                    this.onExpandTab(Number(ID));
                                    break;
                                }
                            }
                        }

                }
            }
            this.scrolling = false;
        }
        if (this.clickScroll && this.ref["tab" + this.state.key]) {
            const scrollHeight = document.documentElement.scrollHeight;
            const innerHeight = window.innerHeight;
            const offsetTop = this.ref["tab" + this.state.key].offsetTop;
            if (scrollHeight - offsetTop >= innerHeight)  {
                if (this.scrollTop === this.ref["tab" + this.state.key].offsetTop) {
                    this.clickScroll = false;
                }
            } else if (this.scrollTop === scrollHeight - innerHeight) {
                this.clickScroll = false;
            }
        }
        this.scrollTop = window.scrollY;
    };

    getInfo = () => {
        const {location} = this.props;
        if (location && location.state) {
            return {
                EmployeeID: location.state.EmployeeID,
                FormID: location.state.FormID,
            }
        } else {
            return {
                EmployeeID: Config.getHREmployeeID(),
                isClickPersonal: true
            }
        }
    };

    handleChange = (evt, value) => {
        const {key} = this.state;
        if (key !== value) {
            const scrolling = this.scrolling;
            this.setState({key: value}, () => {
                if (!this.tabOpened["tab" + value]) {
                    this.onExpandTab(value, () => {
                        if (this.ref["tab" + value] && !scrolling) {
                            this.clickScroll = true;
                            window.scrollTo({top: this.ref["tab" + value].offsetTop, behavior: 'smooth'});
                        }
                    });
                } else {
                    if (this.ref["tab" + value] && !scrolling) {
                        this.clickScroll = true;
                        window.scrollTo({top: this.ref["tab" + value].offsetTop, behavior: 'smooth'});
                    }
                }
            });
        }
    };


    handleChangeEmployee = (key, e) => {
        if(!key) return false;
        if(key === "EmployeeID" && typeof e.value === "object" && e.value?.hasOwnProperty("EmployeeID")) {
            const {EmployeeID} = e.value;
            this.filterCboEmployees.Employee = EmployeeID;
            this.handleChange(null, 0);
            if (this.refs) {
                Object.keys(this.refs).forEach(ref => {
                    if (this.refs[ref]) this.refs[ref].loadGrid();
                });
            }
        }
    };

    onExpandTab = (ID, cb) => {
        if (!ID && ID !== 0) return false;
        if (!this.state.loading && !this.await) {
            this.tabOpened["tab" + ID] = true;
            this.tabOpening = true;
            this.setState({["tab" + ID]: !(this.state["tab" + ID] || false)}, () => {
                setTimeout(() => {
                    cb && cb();
                    this.tabOpening = false;
                }, 300);
            });
        }
    };

    redirectScreen = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2002",
            state: {
                EmployeeID: this.filterCboEmployees.Employee || Config.profile.HREmployeeID
            }
        });
    };

    onHistory = () => {
        this.redirectScreen()
    };

    renderButtonTab = (e) => {
        return (
            e.visible && <Button variant={'outlined'} color={'default'} onClick={() => e.onClick()}
                                 style={{minWidth: 20, padding: 12}}>
                <Icon className={e.direction === 'left' ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}/>
            </Button>
        );
    };

    onBack = () => {
        const {FormID} = this.getInfo();
        if (FormID)
            browserHistory.goBack();
        else
            browserHistory.push(Config.getRootPath() + "W09F2225");
    };

    renderContent = (ID) => {
        let permission = 0;
        let {EmployeeID, FormID, isClickPersonal} = this.getInfo();
        const isHidden =  (isClickPersonal || FormID === "W09F2225") ? "" : "hidden";
        EmployeeID = this.filterCboEmployees.Employee || EmployeeID;
        if (!ID && ID !== 0) return null;
        switch (ID + 1) {
            case 1:
                return this.tabOpened["tab" + ID] ? <W09F2000Employees ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} isHidden={isHidden} onLoad={this.loadInfo}/> : <></>;
            case 2:
                return this.tabOpened["tab" + ID] ? <W09F2000Personal ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} isHidden={isHidden} onLoad={this.loadInfo}/> : <></>;
            case 3:
                return this.tabOpened["tab" + ID] ? <W09F2000Family ref={"tab" + ID} mode={ID + 1} employeeID={EmployeeID} onLoad={this.loadInfo}/> : <></>;
            case 4:
                return this.tabOpened["tab" + ID] ? <W09F2000Contracts ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 5:
                return this.tabOpened["tab" + ID] ? <W09F2000Leave ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 6:
                return this.tabOpened["tab" + ID] ? <W09F2000Attachments ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 7:
                return this.tabOpened["tab" + ID] ? <W09F2001HR mode={ID + 1} EmployeeID={EmployeeID}/> : <></>;
            case 8:
                return this.tabOpened["tab" + ID] ? <W09F2000HistorySocialInsurance ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 9:
                return this.tabOpened["tab" + ID] ? <W09F2000Evaluation  ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 10:
                return this.tabOpened["tab" + ID] ? <W09F2000TrainingHistory  ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo}/> : <></>;
            case 11:
                permission = this?.state?.iPermission || 0;
                return this.tabOpened["tab" + ID] ? <W09F2000Insurrance ref={"tab" + ID} mode={ID + 1} onLoad={this.loadInfo} permission={permission > 0 || EmployeeID === Config.getHREmployeeID()}  /> : <></>;
            default:
                break;
        }
        return <div>{Config.lang("Khong_co_du_lieu")}</div>;
    };

    getUser = (data) => {
        if (typeof data === "object") return data;
        const {dataCboEmployees} = this.state;
        return dataCboEmployees && dataCboEmployees.rows &&
            dataCboEmployees.rows.find(val => val.EmployeeID === data);
    };

    renderFieldEmployee = (data) => {
        const {classes} = this.props;
        const user =  this.getUser(data);
        if (data) {
            return (
                <div className={"display_row align-right"}
                     style={{width: "100%", height: '100%'}}>
                    <UserImage data={user} width={32} height={32} />
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             value={`${(user && user.EmployeeName) || "" }`}
                             readOnly={true}

                    />
                </div>
            );
        } else {
            return (
                <div className={"display_row align-right"} style={{width: "100%", height: '100%'}}>
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             defaultValue={""}
                             readOnly={true}

                    />
                </div>
            );
        }
    };

    onAdd = () => {
        const {EmployeeID} = this.getInfo();
        const dataEmployee = this.filterCboEmployees.Employee || EmployeeID;
        this.setState({rowData: {
                mode: "add",
                propertyID: "RELATIVE",
                OldPropertyID: "",
                RelativeID: "",
                IGE: "",
                employeeID: dataEmployee
            },showW09F2210Popup: true});
    };

    onCloseModal = () => {
        this.setState({showW09F2210Popup: false});
    };


    render() {
        const {infoEmployee, loading, key, dataCboEmployees, loadingCboEmployee, showW09F2210Popup, rowData} = this.state;
        // const permission = iPermission?.find(i => i.FormID === 'W09F2000')?.IsPermission ?? 0;
        // if (permission <= 0) return null;
        const {classes} = this.props;
        const {EmployeeID, FormID} = this.getInfo();
        const isHidden = EmployeeID === Config.getHREmployeeID() ?  "" : (FormID === "W09F2225") ? "" : "hidden";
        return (
            <React.Fragment>
                {showW09F2210Popup && <W09F2210 open={showW09F2210Popup}
                                                onClose={() => this.onCloseModal()}
                                                mode={"edit"}
                                                rowData={rowData}/>}
                <FormGroup className={classes.actionBar}>
                    <ActionToolbar
                        className={classes.toolbar}
                        title={Config.lang("Chi_tiet_nhan_vien")}
                        onBack={this.onBack}
                        alignment={"flex-end"}
                    >
                        <div className={"display_row align-center valign-middle"}>
                            <ButtonGeneral
                                name={Config.lang("Lich_su_DCTT")}
                                color={"primary"}
                                variant={"contained"}
                                className={isHidden}
                                style={{ textTransform: "uppercase"}}
                                size={"large"}
                                icon={<Icons className={"far fa-history"}/>}
                                onClick={this.onHistory}
                            />
                        </div>
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <LoadPanel
                        position={{ my: 'center', of: loading }}
                        visible={!!loading}
                        showIndicator={true}
                        shading={false}
                        showPane={true}
                    />
                </FormGroup>
                <div className={classes.flexBox}>
                    <div className={classes.flexCol + " " + classes.flexColLeft}>
                        <Hidden only="xs">
                            <FormGroup>
                                <Row>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboEmployees}
                                                skip={this.filterCboEmployees.skip}
                                                limit={this.filterCboEmployees.limit}
                                                displayExpr={"EmployeeName"}
                                                keyExpr={'EmployeeID'}
                                                valueExpr={'EmployeeID'}
                                                value={this.filterCboEmployees.Employee || infoEmployee}
                                                stylingMode={'underlined'}
                                                loading={loadingCboEmployee}
                                                // label={Config.lang('DHR_Nhan_vien')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.EmployeeID + ' - ' + data.EmployeeName;
                                                }}
                                                disabled={FormID !== "W09F2225"}
                                                fieldRender={this.renderFieldEmployee}
                                                showClearButton={true}
                                                // showCheckBoxMode={"none"}
                                                onValueChanged={e => this.handleChangeEmployee("EmployeeID", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboEmployees.strSearch = e.target.value;
                                                    this.filterCboEmployees.skip = 0;
                                                    this.loadCboEmployees(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboEmployees.skip = e.skip;
                                                    this.filterCboEmployees.limit = e.limit;
                                                    this.loadCboEmployees();
                                                }}
                                            />
                                        </Col>
                                        {/*<Col xs={3} sm={3} md={3} lg={3}>*/}
                                        {/*    <UserImage data={infoEmployee} allowHover={false} width={46} height={46}/>*/}
                                        {/*</Col>*/}
                                        {/*<Col xs={9} sm={9} md={9} lg={9}>*/}
                                        {/*    <div style={{fontSize: '12px',fontWeight: "bold"}}>*/}
                                        {/*        <UserName data={infoEmployee} allowHover={false}/>*/}
                                        {/*    </div>*/}
                                        {/*</Col>*/}
                                    </div>
                                </Row>
                            </FormGroup>
                        </Hidden>
                        <Tabs
                            value={key}
                            onChange={this.handleChange}
                            indicatorColor='primary'
                            textColor="primary"
                            style={{fontSize: '16px', width: "240px"}}
                            aria-label="Vertical tabs example"
                            ScrollButtonComponent={this.renderButtonTab}
                            orientation="vertical"
                            className={classes.tab}
                            classes={{ indicator: classes.bgIndicator }}
                        >
                            {tabs && tabs.map(tab => {
                                const icon = <Icons src={tab.icon} width={16} height={16} color={"primary"}/>;
                                return <Tab
                                    key={tab.ID}
                                    className={classes.root}
                                    classes={{root: classes.rootTab, selected: classes.selected, wrapper: classes.wrapper}}
                                    label={
                                        <TabItems value={tab.ID} keys={key} icon={icon}
                                                  text={Config.lang(tab.name)}/>
                                    }
                                />
                            })}
                        </Tabs>
                    </div>
                    <div style={{clear: "both"}} />
                    <div className={classes.tabContent}>
                        {tabs && tabs.map(tab => {
                            const icons = <Icons color={"primary"} name={!!this.state["tab" + tab.ID] ? "down" : "right"} width={24} height={24} />;
                            return (
                                <ExpansionPanel
                                    key={tab.ID}
                                    ref={ref => this.ref["tab" + tab.ID] = ref}
                                    expanded={this.state["tab" + tab.ID] || false}
                                    className={classes.collapseBoard}
                                    onChange={() => this.onExpandTab(tab.ID)}
                                >
                                    <div className={"display_row align-center"}>
                                        <ExpansionPanelSummary
                                            className={classes.ExpansionPanelSummary}
                                        >
                                            {icons}
                                            <Typography className={classes.heading}>
                                                {Config.lang(tab.name) || ""}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ButtonGeneral name={Config.lang('Them')}
                                                       className={isHidden || ((tab.ID === 2) ? "" : "hidden")}
                                                       typeButton={'add'}
                                                       style={{ textTransform: 'uppercase' }}
                                                       size={"small"}
                                                       onClick={this.onAdd}
                                        />
                                    </div>

                                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                        {this.renderContent(tab.ID)}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default compose(connect((state) => ({
        getInfo: state.W09F2000.getInfo
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W09f2000Actions: bindActionCreators(W09f2000Actions, dispatch),
    })
),withStyles(styles,{withTheme: true}))(W09F2000);
