/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 5/7/2021
 * @Example
 */

import React, { useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, TextInput, Button, Typography, Notify } from "diginet-core-ui/components";
import Config from "../../../../config";
import MForm from "../../../common/form-material/form";
import W00F1016 from "../W00F1016/W00F1016";
import * as userActions from "../../../../redux/user/user_actions";
import { useDispatch } from "react-redux";

const W00F1015 = props => {
    const { open, onClose } = props;
    const [error, setError] = useState({});
    const [email, setEmail] = useState("");
    const [openPopupW00F1016, setOpenPopupW00F1016] = useState(false);

    const dispatch = useDispatch();

    const Language = useRef(Config.language || "84");
    const alertRef = useRef(null);

    const handleChange = (key, e) => {
        if (!e) return false;
        switch (key) {
            case "Email":
                setEmail(e.target?.value);
                break;

            default:
                break;
        }
    };

    const setStateErrorText = value => {
        setError({ ...value });
        return Object.keys(value).length !== 0;
    };
    const onSubmit = () => {
        const validateRules = [
            {
                name: "Email",
                rules: ["isRequired", "isEmail"],
                value: email,
            },
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            return false;
        }
        setError({});
        const params = {
            HostID: "",
            Mode: 0,
            Language: Language.current,
            FormID: "W00F1015",
            Email: email,
        };
        dispatch(
            userActions.forgotPassword(params, async (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        alertRef.current(message, {
                            color: "danger",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                    } else {
                        alertRef.current(Config.lang("Gui_thanh_cong"), {
                            color: "success",
                            progressing: true,
                            autoDisappear: true,
                            clearAble: false,
                        });
                        setOpenPopupW00F1016(true);
                        onClose();
                    }
                }
            })
        );
    };

    const renderPopupW00F1016 = () => {
        return (
            <W00F1016
                email={email}
                open={openPopupW00F1016}
                onClose={() => {
                    setEmail("");
                    setOpenPopupW00F1016(false);
                }}
            />
        );
    };

    const _onClose = () => {
        setEmail("");
        onClose();
    };

    return (
        <>
            <Modal onClose={onClose} open={open} pressESCToClose={false} moveable={false} dragAnyWhere={false} moveOutScreen={false} width={640} zIndex={1300}>
                <ModalHeader title={Config.lang("Quen_mat_khau")} />
                <ModalBody>
                    <Typography>{Config.lang("Vui_long_nhap_email_de_doi_mat_khau")}</Typography>
                    <TextInput
                        autoFocus
                        error={error && error["Email"]}
                        validates={["isEmail"]}
                        placeholder={Config.lang("Nhap_email")}
                        value={email}
                        onChange={e => handleChange("Email", e)}
                    />
                    <div className="display_row valign-bottom">
                        <Button
                            style={{ textTransform: "uppercase", minWidth: 105 }}
                            text={Config.lang("Gui")}
                            viewType={"filled"}
                            color={"info"}
                            onClick={onSubmit}
                        />
                        <Button
                            style={{ textTransform: "uppercase", minWidth: 105, marginLeft: 8 }}
                            text={Config.lang("Huy")}
                            viewType={"outlined"}
                            onClick={_onClose}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {openPopupW00F1016 && renderPopupW00F1016()}
            <Notify getAddNotify={ref => (alertRef.current = ref)} />
        </>
    );
};

export default W00F1015;
