import React from "react";
import {withStyles} from "@material-ui/core";
import {Col, FormGroup, Row} from 'react-bootstrap';
import Config from '../../../../config';
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import propTypes from "prop-types"
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import {Combo, MForm} from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import * as W09F2001Actions from '../../../../redux/W0X/W09F2001/W09F2001_actions';
import * as generalActions from "../../../../redux/general/general_actions";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";

const styles = theme => ({
    legend: {
        fontSize: 16,
        fontWeight: 500,
        marginTop: 24,
        marginBottom: 12,
        border: "none"
    },
    colorInfo: {
        backgroundColor: theme.palette.info.main,
    },
    Combo: {
        marginRight: 10,
        margin: 0,
        minWidth: 300,
        width: 300,
        '& .dx-textbox ': {
            height: 33
        },
        '& input': {
            padding: '0 0 0 10px !important'
        },
        '& label': {
            marginTop: -5
        },
    },
    Button: {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: 'white',
    }
});

class W09F2000Contracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataContracts: {
                rows: [],
                total: 0
            },
            dataSub: {
                rows: [],
                total: 0
            },
            cbReport: { rows: [], total: 0 },
            error:{}
        };
        this.filterMain = {
            skip: 0,
            limit: 5,
        };
        this.filterSub = {
            skip: 0,
            limit: 5,
        };

        this.cbReportFilter = {
            limit: 10,
            slip: 0
        };
    }

    loadGrid = async (type = "") => {
        const {mode, onLoad} = this.props;
        let {skip, limit} = this.filterMain;
        if (type === "SubContract") {
            skip = this.filterSub.skip;
            limit = this.filterSub.limit;
        }
        if (onLoad) {
            await onLoad(mode, type, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (type === "SubContract") {
                    this.setState({dataSub: {rows: data && data.rows ? data.rows : [], total: data && data.total ? data.total : 0 }});
                } else {
                    this.setState({dataContracts: {rows: data && data.rows ? data.rows : [], total: data && data.total ? data.total : 0 }});
                }
            }, skip, limit, type === "Contract");
        }
    };

    componentDidMount = async () => {
        await this.loadGrid("Contract");
        this.loadGrid("SubContract");
        this.getCbReport();
    };

    handleChangePageMain = (page) => {
        this.filterMain.skip = page * this.filterMain.limit;
        this.loadGrid("Contract");
    };

    handleChangePerPageMain = (perPage) => {
        this.filterMain.skip = 0;
        this.filterMain.limit = perPage;
        this.loadGrid("Contract");
    };

    handleChangePageSub = (page) => {
        this.filterSub.skip = page * this.filterSub.limit;
        this.loadGrid("SubContract");
    };

    handleChangePerPageSub = (perPage) => {
        this.filterSub.skip = 0;
        this.filterSub.limit = perPage;
        this.loadGrid("SubContract");
    };

    renderItem = (e) => {
        if (!e) return false;
        const {classes} = this.props;
        const {data} = e;
        return (
            <div className={"display_row align-center"}>
                <Chip component={"div"} color={"default"}
                      className={"mgr5 " + (data && Number(data.ValidContractStatus) === 1 ? classes.colorInfo : "")}
                    style={{width: 20, height: 20}}
                />
                <span>{data.LaborContractNo || ""}</span>
            </div>
        );
    };

    getCbReport = (isLoadmore = false) => {
        const {cbReport} = this.state;
        const params = {
            limit: this.cbReportFilter.limit,
            skip: this.cbReportFilter.skip,
            TranTypeID: "W6_HDLD"
        };
        this.props.W09F2001Actions.getCbReport(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            const rows = data && data.rows ? data.rows : data;
            const total = data && data.total ? data.total : data.length;
            const dataT = {
                    rows: !isLoadmore ? rows : cbReport.rows.concat(rows),
                    total: total,
            };
            this.setState({cbReport: dataT});
        })

    };

    onExport = () => {
        const {ReportID, cbReport, dataContracts} = this.state;
        const validateRules = [
            {
                name: "ReportID",
                rules: "isRequired",
                value: ReportID
            }
        ];
        const valid = MForm.formValidation(validateRules);
        if (Object.keys(valid).length > 0) {
            this.setState({error:valid});
            return false;
        }

        const report = cbReport.rows.find(i=>i.ReportID === ReportID);

        const params = {
            ReportTypeID: report.ReportTypeID,
            ReportID: report.ReportID,
            TitleU: report.TitleU,
            Remark:report.Remark,
            URL:report.URL,
            FileExt:report.FileExt,
            ExportType:report.FileExt,
            data:JSON.stringify(dataContracts.rows)
        };

        this.props.generalActions.exportMulti(params, (err, data)=>{
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            else if (data) {
                const link = document.createElement('a');
                link.href = data.URL ? data.URL : "";
                link.download = data.fileName ? data.fileName : "";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        })

    };

    render() {
        const {classes} = this.props;
        const {ReportID, cbReport, error, dataContracts, dataSub} = this.state;

        return (
            <div style={{width: "100%"}}>
                <div className="display_row align-center  " style={{ justifyContent: 'flex-end', paddingBottom: 20 }}>
                    <Combo.LoadMore
                        acceptCustomValue={false}
                        required
                        error={error && error['ReportID']}
                        className={classes.Combo}
                        dataSource={cbReport}
                        skip={this.cbReportFilter.skip}
                        limit={this.cbReportFilter.limit}
                        displayExpr={"TitleU"}
                        shrink={false}
                        keyExpr={"ReportID"}
                        valueExpr={"ReportID"}
                        name="ReportID"
                        value={ReportID}
                        loading={false}
                        stylingMode={"filled"}
                        label={ReportID ? '' : Config.lang("DHR_Mau_bao_cao")}
                        onValueChanged={(e) => this.setState({ ReportID: e.value.ReportID })}
                        onLoadMore={(e) => {
                            this.cbReportFilter.skip = e.skip;
                            this.getCbReport(true);
                        }}
                    />
                    <ButtonGeneral disabled={!ReportID}
                                   name={"In"}
                                   className={classes.Button}
                                   variant={"text"}
                                   onClick={this.onExport}
                    />
                </div>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                dataSource={dataContracts && dataContracts.rows ? dataContracts.rows : []}
                                totalItems={dataContracts && dataContracts.total ? dataContracts.total : 0}
                                itemPerPage={this.filterMain.limit}
                                skipPerPage={this.filterMain.skip}
                                onChangePage={this.handleChangePageMain}
                                onChangePerPage={this.handleChangePerPageMain}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("DHR_So_HDLD")}
                                    dataField={"LaborContractNo"}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    caption={Config.lang("DHR_Loai_HDLD")}
                                    dataField={"ContractTypeName"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ngay_lap")}
                                    dataField={"PlaceDate"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Thoi_han_hop_dong")}
                                    dataField={"ContractPeriod"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Trang_thai_lam_viec")}
                                    dataField={"StatusName"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Phu_luc")}
                                    dataField={"IsSubContract"}
                                    alignment={"center"}
                                    cellRender={(e) => {
                                        const {data} = e;
                                        return <Checkbox
                                            checked={!!data.IsSubContract}
                                            color="primary"
                                            size={"small"}
                                            style={{ padding: "3px" }}
                                            disabled={true}
                                        />
                                    }}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
                <div>
                    {/*Phu luc*/}
                    <fieldset>
                        <legend className={classes.legend}>{Config.lang("DHR_Phu_luc")}</legend>
                        <FormGroup>
                            <GridContainer
                                dataSource={dataSub && dataSub.rows ? dataSub.rows : []}
                                totalItems={dataSub && dataSub.total ? dataSub.total : 0}
                                listPerPage={[5, 10, 15, 20]}
                                itemPerPage={this.filterSub.limit}
                                skipPerPage={this.filterSub.skip}
                                onChangePage={this.handleChangePageSub}
                                onChangePerPage={this.handleChangePerPageSub}
                                typePaging={"remote"}
                                pagerFullScreen={false}
                                loading={false}
                            >
                                <Column
                                    caption={Config.lang("DHR_So_phu_luc")}
                                    dataField={"SubContractSerial"}
                                />
                                <Column
                                    caption={Config.lang("DHR_So_HDLD")}
                                    dataField={"LaborContractNo"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Loai_phu_luc")}
                                    dataField={"SubContractTypeName"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Thoi_han_phu_luc")}
                                    dataField={"SubContractPeriod"}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ngay_ky_phu_luc")}
                                    dataField={"SubContractDate"}
                                />
                            </GridContainer>
                        </FormGroup>
                    </fieldset>
                </div>
            </div>
        );
    }

}

W09F2000Contracts.propTypes = {
    data: propTypes.any,
};

export default compose(
    connect(
        () => ({}),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W09F2001Actions: bindActionCreators(W09F2001Actions, dispatch),
        }),
        null,
        { forwardRef: true })
    , withStyles(styles))
(W09F2000Contracts);

