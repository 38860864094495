import React, { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { withStyles, Avatar } from "@material-ui/core";

import ButtonGeneral from "../../../common/button/button-general";
import Config from "../../../../config";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";

const styles = theme => {
  return {
    avatarContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "space-around",
      "&:hover $buttonHover": {
        opacity: 1,
        transition: "0.6s"
      }
    },
    avatar: {
      cursor: "pointer"
    },
    buttonHover: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 43,
      opacity: 0,
      transition: "0.6s"
    },
    labelAsterisk: {
      "&::after": {
        content: '" )"'
      },
      "&::before": {
        content: '"("'
      },
      paddingLeft: 10,
    }
  };
};

class W09F1501EmployeeInfo extends Component {

  render() {
    const {
      innerRef,
      getCboGenders,
      getCboProvinces,
      getCboDepartments,
      formData,
      onChange,
      onUpoadFile,
      onRemoveFile,
      isEdit,
      errorForm,
      loading,
      isView,
      upload,
      classes
    } = this.props;
    const {
      EmployeeName,
      Sex,
      Telephone,
      NumIDCard,
      NumIDCardDate,
      NumIDCardPlace,
      IDCard,
      IDCardDate,
      IDCardPlace,
      ContactAddress,
      DepartmentID,
      UserPictureURL
    } = formData;
    const isEmptyNumIDCard = _.isEmpty(NumIDCard);
    const isEmptyIDCard = _.isEmpty(IDCard);
    const iEmptySex = _.isEmpty(Sex);
    return (
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 4 }}>
            <Row>
              <Col xs={12}>
                <TextField
                  error={_.get(errorForm, "EmployeeName", false)}
                  required={!isView && true}
                  label={Config.lang("DHR_Ho_va_ten")}
                  variant={"standard"}
                  margin={"normal"}
                  value={EmployeeName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={isEdit || isView}
                  onChange={e => onChange(e, "EmployeeName")}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Combo
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin={"normal"}
                  required={!isView && true}
                  stylingMode={"underlined"}
                  label={Config.lang("DHR_Gioi_tinh")}
                  dataSource={getCboGenders}
                  displayExpr={"SexName"}
                  valueExpr={"Sex"}
                  onValueChanged={e => onChange(e, "Sex")}
                  value={Sex}
                  disabled={isEdit || isView}
                  error={iEmptySex && _.get(errorForm, "Sex", false)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextField
                  label={Config.lang("DHR_So_dien_thoai")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={isView}
                  type={"number"}
                  value={Telephone || ""}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ min: 0 }}
                  onChange={e => onChange(e, "Telephone")}
                  fullWidth
                />
              </Col>
            </Row>
          </div>
          <div className={classes.avatarContainer}>
            <Avatar
              disabled={loading}
              onClick={upload}
              className={classes.avatar}
              style={{
                width: "125px",
                height: "125px",
                marginTop: 50,
                marginLeft: 50
              }}
              src={
                !_.isEmpty(UserPictureURL)
                  ? UserPictureURL
                  : require("../../../../assets/images/general/user_default.svg")
              }
            />
            <input type="file" id="file" onChange={onUpoadFile} ref={innerRef} style={{ display: "none" }} />
            <div className={classes.buttonHover}>
              <ButtonGeneral
                disabled={isView}
                typeButton={"edit"}
                color={"primary"}
                variant={"custom"}
                style={{ textTransform: "uppercase" }}
                size={"large"}
                onClick={upload}
              />
              <ButtonGeneral
                disabled={isView}
                typeButton={"delete"}
                color={"warning"}
                variant={"custom"}
                style={{ textTransform: "uppercase" }}
                size={"large"}
                onClick={onRemoveFile}
              />
            </div>
          </div>
        </div>
        <Row>
          <Col xs={12}>
            <Combo
              InputLabelProps={{
                shrink: true
              }}
              disabled={loading || isView}
              margin={"normal"}
              stylingMode={"underlined"}
              label={Config.lang("DHR_Phong_ban")}
              dataSource={getCboDepartments}
              displayExpr={"DepartmentName"}
              valueExpr={"DepartmentID"}
              onValueChanged={e => onChange(e, "DepartmentID")}
              showClearButton={true}
              value={DepartmentID}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <TextField
              error={isEmptyIDCard && _.get(errorForm, "NumIDCard", false)}
              label={Config.lang("DHR_Chung_minh_nhan_dan")}
              variant={"standard"}
              margin={"normal"}
              disabled={isView}
              type={"number"}
              value={NumIDCard || ""}
              InputLabelProps={{
                shrink: true,
                classes: {
                  asterisk: classes.labelAsterisk
                }
              }}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "NumIDCard")}
              fullWidth
              required={isEmptyIDCard}
            />
          </Col>
          <Col xs={12} md={3}>
            <DateBoxPicker
              InputLabelProps={{
                shrink: true,
                style: { marginTop: -7 }
              }}
              height={30}
              useMaskBehavior={true}
              label={Config.lang("DHR_Ngay_cap")}
              width={"100%"}
              stylingMode={"underlined"}
              shrink={true}
              value={NumIDCardDate}
              onValueChanged={e => onChange(e, "NumIDCardDate")}
              margin={"normal"}
              error={!isEmptyNumIDCard &&_.get(errorForm, "NumIDCardDate", false)}
              disabled={isEmptyNumIDCard || loading}
              required={!isEmptyNumIDCard}
              showClearButton={true}
            />
          </Col>
          <Col xs={12} md={3}>
            <Combo
              InputLabelProps={{
                shrink: true
              }}
              disabled={isEmptyNumIDCard || loading}
              error={!isEmptyNumIDCard &&_.get(errorForm, "NumIDCardPlace", false)}
              margin={"normal"}
              stylingMode={"underlined"}
              label={Config.lang("DHR_Noi_cap")}
              dataSource={getCboProvinces}
              displayExpr={"PopulationName"}
              valueExpr={"PopulationID"}
              onValueChanged={e => onChange(e, "NumIDCardPlace")}
              showClearButton={true}
              value={NumIDCardPlace}
              required={!isEmptyNumIDCard}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <TextField
              error={isEmptyNumIDCard && _.get(errorForm, "IDCard", false) }
              type={"number"}
              label={Config.lang("DHR_The_can_cuoc")}
              variant={"standard"}
              margin={"normal"}
              disabled={isView}
              value={IDCard}
              InputLabelProps={{
                shrink: true,
                classes: {
                  asterisk: classes.labelAsterisk
                }
              }}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "IDCard")}
              fullWidth
              required={isEmptyNumIDCard}
            />
          </Col>
          <Col md={3} xs={12}>
            <DateBoxPicker
              InputLabelProps={{
                shrink: true,
                style: { marginTop: "-7px" }
              }}
              error={!isEmptyIDCard && _.get(errorForm, "IDCardDate", false)}
              height={30}
              useMaskBehavior={true}
              label={Config.lang("DHR_Ngay_cap")}
              width={"100%"}
              stylingMode={"underlined"}
              shrink={true}
              value={IDCardDate}
              onValueChanged={e => onChange(e, "IDCardDate")}
              margin={"normal"}
              disabled={isEmptyIDCard || loading}
              required={!isEmptyIDCard}
              showClearButton={true}
            />
          </Col>
          <Col md={3} xs={12}>
            <Combo
              InputLabelProps={{
                shrink: true
              }}
              error={!isEmptyIDCard && _.get(errorForm, "IDCardPlace", false)}
              margin={"normal"}
              stylingMode={"underlined"}
              label={Config.lang("DHR_Noi_cap")}
              dataSource={getCboProvinces}
              displayExpr={"PopulationName"}
              valueExpr={"PopulationID"}
              onValueChanged={e => onChange(e, "IDCardPlace")}
              showClearButton={true}
              value={IDCardPlace}
              disabled={isEmptyIDCard || loading}
              required={!isEmptyIDCard}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TextField
              disabled={isView}
              onChange={e => onChange(e, "ContactAddress")}
              label={Config.lang("DHR_Dia_chi")}
              variant={"standard"}
              margin={"normal"}
              value={ContactAddress}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ min: 0 }}
              fullWidth
            />
          </Col>
        </Row>
      </div >
    );
  }
}

export default compose(
  connect(state => ({
    getCboGenders: state.W09F1501.getCboGenders,
    getCboProvinces: state.W09F1501.getCboProvinces,
    getCboDepartments: state.general.getCboDepartments
  })),
  withStyles(styles, { withTheme: true })
)(React.forwardRef((props, ref) => <W09F1501EmployeeInfo innerRef={ref} {...props} />));