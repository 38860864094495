import React, { Component } from "react";

import { connect } from "react-redux";
import {bindActionCreators, compose} from "redux";

import _ from "lodash";
import { Row, Col, FormGroup } from "react-bootstrap";
import { withStyles } from "@material-ui/core";

import Attachments from "../../../common/attachments/attachments";
import Config from "../../../../config/index";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import * as generalActions from "../../../../redux/general/general_actions";

const styles = theme => {
  return {
    avatar: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
    }
  };
};
class W09F1501OrgRec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboProjects: false
            }
        };
        this.loading = {
            cboProjects: false
        };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W29F3000",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.loading.cboProjects = false;
            this.setState({loading: this.loading});
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    },
                });
            }
        });
    };

    componentDidMount() {
        this.loadCboProjects();
    }

  render() {
    const {
      getCboDirectManagers,
      getCboPositions,
      formData,
      onChange,
      onUploading,
      onChangeAttachments,
      dataOldAttachments,
      loading,
      isView,
      errorForm,
      onSave
    } = this.props;
    const { DirectManagerID, DateJoined, DutyID, ProjectID, Note } = formData;
    const {dataCboProjects} = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col xs={12} md={6}>
            <Combo
              disabled={loading || isView}
              InputLabelProps={{
                shrink: true
              }}
              margin={"normal"}
              stylingMode={"underlined"}
              label={Config.lang("DHR_Nguoi_quan_ly_truc_tiep")}
              dataSource={Config.storeDataSoureDevExtreme(getCboDirectManagers)}
              displayExpr={"DirectManagerName"}
              valueExpr={"DirectManagerID"}
              onValueChanged={e => onChange(e, "DirectManagerID")}
              showClearButton={true}
              value={DirectManagerID}
            />
          </Col>
          <Col xs={12} md={6}>
            <DateBoxPicker
              showClearButton={true}
              height={30}
              required={!isView && true}
              useMaskBehavior={true}
              label={Config.lang("DHR_Ngay_vao_lam")}
              width={"100%"}
              stylingMode={"underlined"}
              shrink={true}
              InputLabelProps={{ style: { marginTop: "-7px" } }}
              value={DateJoined}
              onValueChanged={e => onChange(e, "DateJoined")}
              margin={"normal"}
              disabled={isView || loading}
              error={_.get(errorForm, "DateJoined", false)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Combo
              disabled={loading || isView}
              InputLabelProps={{
                shrink: true
              }}
              margin={"normal"}
              stylingMode={"underlined"}
              label={Config.lang("DHR_Chuc_vu")}
              dataSource={Config.storeDataSoureDevExtreme(getCboPositions)}
              displayExpr={"DutyName"}
              valueExpr={"DutyID"}
              onValueChanged={e => onChange(e, "DutyID")}
              showClearButton={true}
              value={DutyID}
            />
          </Col>
          <Col xs={12} md={6}>
              <Combo.LoadMore
                  dataSource={dataCboProjects}
                  skip={this.filterCboProject.skip}
                  limit={this.filterCboProject.limit}
                  displayExpr={'ProjectName'}
                  keyExpr={'ProjectID'}
                  valueExpr={'ProjectID'}
                  value={ProjectID}
                  loading={this.state.loading.cboProjects}
                  stylingMode={'underlined'}
                  label={Config.lang('DHR_Du_an')}
                  shrink={true}
                  margin={"normal"}
                  disabled={loading || isView}
                  itemRender={(e) => {
                      const {data} = e;
                      if (!data) return null;
                      return data.ProjectID + ' - ' + data.ProjectName;
                  }}
                  showClearButton={true}
                  // showCheckBoxMode={"none"}
                  onValueChanged={e => onChange(e, "Project")}
                  onInputChanged={(e) => {
                      this.filterCboProject.strSearch = e.target.value;
                      this.filterCboProject.skip = 0;
                      this.loadCboProjects(true);
                  }}
                  onLoadMore={(e) => {
                      this.filterCboProject.skip = e.skip;
                      this.filterCboProject.limit = e.limit;
                      this.loadCboProjects();
                  }}
              />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TextField
              label={Config.lang("DHR_Ghi_chu")}
              variant={"standard"}
              margin={"normal"}
              disabled={isView || loading}
              value={Note}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "Note")}
              fullWidth
            />
          </Col>
        </Row>
        <FormGroup>
          <Row>
            <Col xs={12}>
              <Attachments
                ref={ref => (this.attRef = ref)}
                showButton={true}
                files={dataOldAttachments}
                disabled={isView || loading}
                maxLength={5}
                uploading={loading}
                onUploading={onUploading}
                onChanged={onChangeAttachments}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonGeneral
            name={Config.lang("DHR_Luu")}
            typeButton={"save"}
            disabled={isView || loading}
            style={{ textTransform: "uppercase" }}
            size={"large"}
            onClick={onSave}
          />
        </FormGroup>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    getCboPositions: state.W09F1501.getCboPositions,
    getCboDirectManagers: state.W09F1501.getCboDirectManagers,
    getCboProvinces: state.W09F1501.getCboProvinces,
    getAttachmentsByTransID: state.general.getAttachmentsByTransID
  }),
      dispatch => ({
          generalActions: bindActionCreators(generalActions, dispatch),
      })
  ),
  withStyles(styles, { withTheme: true })
)(W09F1501OrgRec);