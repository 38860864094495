/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/27/2019
 * @Example
 */

import React from "react";
import Config from "../../config";

class CDN extends React.Component {

    static isGetToken = false;

    /**
     * Check token CDN...
     * @param params: token
     * @param callback
     */
    static checkToken(params, cb) {
        this.isGetToken = true;
        ApiCDN.put('/auth/check', params)
            .then(result => {
                if (result && result.data) {
                    if (cb) cb(null, result.data);
                } else {
                    if (cb) cb(result, null);
                }
            });
    };

    /**
     * Check token CDN and get token when current token expiry..
     * @param cb
     */
    static getToken = (cb) => {
        if (Config.getLocalStorage("tokenCDN")) {
            const tokenCDN = JSON.parse(Config.getLocalStorage("tokenCDN"));
            this.checkToken({token: tokenCDN.id}, (error, data) => {
                if (error) {
                    this._getToken(cb);
                    return false;
                }
                if (data) {
                    Config.cdn.token = {
                        id: data.token,
                    };
                    if (cb) cb(null, data);
                } else {
                    this._getToken(cb);
                }
            });
        } else {
            this._getToken(cb);
        }
    };

    /**
     * Function get token....
     * @param cb
     * @private
     */
    static _getToken(cb) {
        this.isGetToken = true;
        ApiCDN.get('/auth/token')
            .then(result => {
                if (result) {
                    if (result.code === 200) {
                        Config.cdn.token = {
                            id: result.token,
                            expire: result.data.expiredAt,
                        };
                        Config.setLocalStorage("tokenCDN", JSON.stringify(Config.cdn.token));
                        if (cb) cb(null, result);
                    }
                } else {
                    if (cb) cb(result, null);
                }
            });
    };

    /**
     * Get token with promise....
     * @returns {Promise<{token: *}|boolean|*>}
     */
    static getTokenSync = async () => {
        if (Config.getLocalStorage("tokenCDN")) {
            const tokenCDN = JSON.parse(Config.getLocalStorage("tokenCDN"));
            this.isGetToken = true;
            const response = await ApiCDN.put('/auth/check',  {token: tokenCDN.id});
            if (response && response.data) {
                return {token: tokenCDN.id};
            } else {
                return await this._getTokenSync();
            }
        } else {
            return await this._getTokenSync();
        }
    };

    /**
     * _get token with promise....
     * @returns {Promise<boolean>}
     * @private
     */
    static _getTokenSync = async () => {
        this.isGetToken = true;
        const response = await ApiCDN.get('/auth/token');
        if (response.code === 200) {
            Config.cdn.token = {
                id: response.token,
                expire: response.data.expiredAt,
            };
            Config.setLocalStorage("tokenCDN", JSON.stringify(Config.cdn.token))
        } else {
            let message = response.message || Config.lang("DHR_Loi_chua_xac_dinh");
            Config.popup.show('INFO', message);
            return false;
        }
        return response;
    };

    //Upload files async...
    static uploadFile = async (files, tokenCDN, cb) => {
        const body = new FormData();
        for(let i = 0; i < files.length; i++)
            body.append('files', files[i]);

        if (!tokenCDN) {
            this.getToken(async () => {
                const response = await ApiCDN.upload('/file/upload', body);
                if (response && response.data) {
                    if (cb) cb(null, response.data);
                } else {
                    if (cb) cb(response, null);
                }
                return null;
            });
        } else {
            const response = await ApiCDN.upload('/file/upload', body, tokenCDN);
            if (response && response.data) {
                if (cb) cb(null, response.data);
            } else {
                if (cb) cb(response, null);
            }
            return null;
        }

    };

    /**
     * Get token with async promise....
     * @param files
     * @param tokenCDN
     * @returns {Promise<null|boolean>}
     */
    static uploadFileSync = async (files, tokenCDN) => {
        const _initToken = await this.getTokenSync();
        if (!tokenCDN) {
            tokenCDN = _initToken && _initToken.token ? _initToken.token : (Config.cdn.token.id ? Config.cdn.token.id : "");
        }
        if (!tokenCDN) {
            Config.popup.show('INFO', Config.lang("DHR_Loi_khong_lay_duoc_token_CDN"));
            return false;
        }
        const body = new FormData();
        for(let i = 0; i < files.length; i++)
            body.append('files', files[i]);

        const response = await ApiCDN.upload('/file/upload', body, tokenCDN);
        if (response) {
            return response;
        }
        return null;
    };

    /**
     * Remove files CDN (allow multiple)...
     * @param params
     * @param cb
     */
    static removeFile = (params, cb) => {
        this.getToken(() => {
            const response = ApiCDN.delete('/file/remove', params);
            if (response && response.data) {
                if (cb) cb(null, response);
            } else {
                if (cb) cb(response, null);
            }
        });
    };

    /**
     * Remove files CDN with promise (allow multiple)..
     * @param params
     * @returns {Promise<null|*>}
     */
    static removeFileSync = async (params) => {
        const resToken = await this.getTokenSync();
        if (resToken && resToken.code === 200) {
            const response = await ApiCDN.delete('/file/remove', params);
            if (response && response.data) {
                return response.data;
            }
            return null;
        }
    };

    static uploadStatus = (params, cb) => {
        this.getToken( async () => {
            const response = null;//await ApiCDN.put('/file/upload', params);
            if (response && response.data) {
                if (cb) cb(null, response.data);
            } else {
                if (cb) cb(response, null);
            }
            return null;
        });
    };
}

export class ApiCDN {
    static headers() {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': 'content-type,token,secret'
        };

        if (CDN.isGetToken) {
            CDN.isGetToken = false;
            headers.secret = Config.cdn.secret
        } else {
            headers.token = Config.cdn.token.id;
        }

        return headers;
    }

    static get(route) {
        return this.xhr(route, null, 'GET');
    }

    static put(route, params) {
        return this.xhr(route, params, 'PUT');
    }

    static post(route, params) {
        return this.xhr(route, params, 'POST');
    }

    static delete(route, params) {
        return this.xhr(route, params, 'DELETE')
    }

    static deleteCDN(route, params, tokenCDN) {
        const url = Config.getCDNPath(false) + route;
        let options = {
            method: 'DELETE',
            headers: ApiCDN.headers(),
            body: params,
        };
        options.headers.token = tokenCDN;

        return fetch(url, options)
            .then(resp => {
                if (resp && resp.ok) {
                    return resp.json();
                }

                return resp.json().then(err => {
                    ApiCDN.checkSystemError(err);
                });
            });
    }

    static upload(route, params, tokenCDN) {
        const url = Config.getCDNPath(false) + route;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'token': tokenCDN ? tokenCDN : Config.cdn.token.id
            },
            body: params
        };

        return fetch(url, options)
            .then(async (resp) => {
                if (resp && resp.ok) {
                    return await resp.json();
                }

                return resp.json().then(err => {
                    ApiCDN.checkSystemError(err);
                });
            });
    }

    static xhr(route, params, method) {

        let url = route.indexOf("://");
        url = url === -1 ? Config.getCDNPath(false) + route : route;
        const options = {
            method: method,
            headers: ApiCDN.headers(),
            body: params ? JSON.stringify(params) : null
        };

        // console.log('==== url: ', url);
        // console.log('==== options: ', options);

        return fetch(url, options)
            .then(async (resp) => {
                if (resp && resp.ok) {
                    // console.log('===== API.xhr => data:', resp);
                    try {
                        const data = await resp.json();

                        // console.log('===== API.xhr => data:', data);
                        if (data && data.code && data.message) {
                            return ApiCDN.checkSystemError(data);
                        } else {
                            return data;
                        }

                    } catch (e) {
                        return ApiCDN.checkSystemError({code: 'SYS001', message: 'System error!'});
                    }
                } else {
                    return ApiCDN.checkSystemError({code: 'SYS001', message: 'System error!'});
                }
            }).catch(e => ApiCDN.checkSystemError(e));
    }

    static checkSystemError = (error) => {
        const code = error.code || null;

        switch (code) {
            case "SYS001":
                error = {code: 'SYS001', message: 'System error!'};
                break;
            case "SYS002":
                error = {code: 'SYS002', message: "Authentication token is required"};
                break;
            case "SYS003":
                error = {code: 'SYS003', message: "Authentication token are not matching"};
                break;
            case "SYS004":
                error = {code: 'SYS004', message: "Authentication token is expired"};
                alert("Phiên làm việc đã hết hạn.\nVui lòng đăng nhập lại.");
                break;
            case "SYS005":
                error = {code: 'SYS005', message: "Authentication error request timeout"};
                break;
            case "SYS006":
                error = {code: 'SYS006', message: "Update token fail"};
                break;
            case "SYS007":
                error = {code: 'SYS007', message: "Socket error"};
                break;
            case "SYS008":
                error = {code: 'SYS008', message: "The data is not in JSON format"};
                break;
            case "SYS009":
                error = {code: 'SYS009', message: "The data is not in list"};
                break;
            case "SYS010":
                error = {code: 'SYS010', message: "The data is not number"};
                break;
            case "SYS011":
                error = {code: 'SYS011', message: "The data is unique"};
                break;
            case "SYS062":
                error = {code: 'SYS062', message: "User has logged out, please login again"};
                break;
            case "SYS500":
                error = {code: 'SYS500', message: "The unknown error has occurred"};
                break;
            default:
                error = {code: 'SYS500', message: "The unknown error has occurred", ...error};
                break;
        }

        // if (error.code === 'SYS500' || error.code === 'SYS001') {
        //     browserHistory.push(Config.getRootPath() + 'error-page');
        //     return false;
        // }
        // if (error.code === 'SYS002' || error.code === 'SYS003' || error.code === 'SYS030') {
        //     const message = error.code === 'SYS030' ? Config.lang('DHR_ko_du_quyen') :Config.lang('DHR_Dang_nhap_lai');
        //     Config.popup.show('INFO',message,null,null,()=>{
        //         Config.logout();
        //     });
        //     return false;
        //
        // }

        return error;
    }
}

export default CDN;
