/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/30/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as historyActions from "../../../../redux/history/history_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import * as w00f0010Actions from "../../../../redux/W0X/W00F0010/W00F0010_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, TextField } from "../../../common/form-material";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import DateBoxPicker from "../../../common/form-material/date-box";
import {
    Typography,
    IconButton,
} from "@material-ui/core";
import Icons from "../../../common/icons";
import _ from "lodash";
import moment from "moment";

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    hide: {
        display: "none",
    },
    panel: {
        transition: "all .4s",
    },
    panelForm: {
        transition: "all .5s",
        opacity: 1,
    },
    hiddenOpacity: {
        opacity: 0,
    },
    leftPanelshowDrawer: {
        width: "74px",
    },
    rightPanelshowDrawer: {
        width: "calc(100% - 74px)",
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + " !important",
        "& svg": {
            "& path": {
                fill: theme.palette.primary.contrastText,
            },
        },
    },
});
class W00F0100Nav extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            cboEmployeeLoading: false,
            dataModule: [],
            dataActions: [
                {
                    action: "0",
                    actionName: "Thêm mới",
                },
                {
                    action: "1",
                    actionName: "Sửa",
                },
                {
                    action: "2",
                    actionName: "Khác",
                },
                {
                    action: "3",
                    actionName: "Xóa",
                },
            ],
            dataCboTransaction: [],
            Content: "",
            actions: null,
            modules: "",
            transactions: "",
            Datefrom: "",
            Dateto: "",
        };
        this.changePage = false;
        this.filterCboEmployees = {
            limit: 15,
            skip: 0,
            strSearch: "",
        };
        this.genders = [
            {
                SexID: 0,
                SexName: Config.lang("DHR_NamU"),
            },
            {
                SexID: 1,
                SexName: Config.lang("DHR_Nu"),
            },
        ];
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    handleChange = (nameField, e) => {
        if (!nameField) return false;
        if (!_.isUndefined(e.value)) {
            this.setState({ [nameField]: e.value });
        } else {
            this.setState({ [nameField]: e.target.value });
        }
    };

    onFilter = () => {
        this.loadHistorySearch();
    };

    loadCboEmployees = async (isReset) => {
        const { FormID } = this.props;
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID || "W00F0100",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ cboEmployeeLoading: true });
        await this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ cboEmployeeLoading: false });
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };
    loadCboModules = async () => {
        this.setLoading("Module", true);
        await this.props.w00f0010Actions.getCboModuleW00F0010({}, (error, data) => {
            this.setLoading("Module", false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState(
                    {
                        dataModule: data,
                    },
                    () => {
                        if (data.length > 0) {
                            let all = data.find((item) => item.ModuleID === "%");
                            this.setState({ modules: all.ModuleID });
                        }
                    }
                );
                this.props.getCboModule(data);
            }
        });
    };

    loadCboTransaction = () => {
        this.setLoading("CboTransaction", true);
        const param = {
            Language: Config.language || "84",
        };
        this.props.w00f0010Actions.loadCboTransaction(param, (error, data) => {
            this.setLoading("CboTransaction");
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ dataCboTransaction: data });
            }
        });
    };

    loadHistorySearch = () => {
        const {
            Content,
            Employee,
            modules,
            actions,
            transactions,
            Dateto,
            Datefrom,
        } = this.state;
        let begin = Datefrom
            ? moment(Datefrom).startOf("day").format("YYYY-MM-DD HH:mm:ss")
            : "",
            end = Dateto
                ? moment(Dateto).endOf("day").format("YYYY-MM-DD HH:mm:ss")
                : "";

        let obj = {
            userID: Employee?.UserID || "",
            moduleID: modules === "%" ? "" : modules,
            transactionID: transactions || "",
            action: actions || "",
            createdAt: { ">": begin, "<": end },
            text: Content
        };
        /**
         * condition: {
         *     "and": [
         *         {
         *           position: "GD",
         *         },
         *        {
         *           "or" : [
         *             {userID: "a"},
         *             {userID: "b"}
         *           ]
         *         }
         *       ]
         *   }
         */
        Object.keys(obj).forEach((item) => {
            switch (item) {
                case "createdAt":
                    if (obj.createdAt[">"] === "") {
                        delete obj.createdAt[">"];
                    }
                    if (obj.createdAt["<"] === "") {
                        delete obj.createdAt["<"];
                    }
                    break;
                case "text":
                    if (!Config.isEmpty(obj[item])) {
                        obj[item] = {"contains": Content};
                    } else {
                        delete obj[item];
                    }
                    break;
                default:
                    if (Config.isEmpty(obj[item])) {
                        delete obj[item];
                    }
                    break;
            }

        });
        if (_.isEmpty(obj.createdAt)) {
            delete obj["createdAt"];
        }
        let sort = [{ updatedAt: "DESC" }];
        const param = {
            sort: JSON.stringify(sort),
            condition: JSON.stringify(obj),
        };

        this.props.params(param);
    };

    componentDidMount = async () => {
        await this.loadCboEmployees();
        await this.loadCboModules();
        this.loadHistorySearch();
        this.loadCboTransaction();
    };

    handleClose = () => {
        const { handleClose } = this.props;
        if (handleClose) handleClose();
    };
    handleOpen = () => {
        const { handleOpen } = this.props;
        if (handleOpen) handleOpen();
    };

    render() {
        const {
            classes,
            showDrawer,
        } = this.props;
        const {
            dataCboEmployees,
            cboEmployeeLoading,
            dataModule,
            loadingModule,
            Employee,
            modules,
            actions,
            dataActions,
            loadingCboTransaction,
            dataCboTransaction,
            transactions,
            Datefrom,
            Dateto,
        } = this.state;
        const onOpen = showDrawer ? this.handleClose : this.handleOpen,
            leftRight = showDrawer ? "left" : "filter";
        return (
            <React.Fragment>
                <ActionToolbar
                    alignment={showDrawer ? "space-between" : "center"}
                    // title={Config.lang("DHR_Loc_chon_nhan_vien")}
                    showBorder={false}
                    upperCase={false}
                    className={!showDrawer ? classes.titleColorFilter : ""}
                    style={{
                        marginTop: 0,
                        backgroundColor: "#EDEEF3",
                        height: "57.6px",
                        padding: "0 15px",
                        marginBottom: 15,
                        fontSize: "1rem",
                    }}
                    allwaysTop={false}
                >
                    <div className={"display_row align-center valign-middle"}>
                        <IconButton
                            color="inherit"
                            aria-label="menu"
                            onClick={onOpen}
                        >
                            <Icons name={"filter"} />
                        </IconButton>
                        {showDrawer && (
                            <Typography>{Config.lang("DHR_Loc_chon_hanh_dong")}</Typography>
                        )}
                    </div>
                    {showDrawer && (
                        <IconButton
                            size={"medium"}
                            color="inherit"
                            aria-label="menu"
                            onClick={onOpen}
                        >
                            <Icons name={leftRight} />
                        </IconButton>
                    )}
                </ActionToolbar>
                <div
                    className={
                        classes.panelForm + " form-field " + (!showDrawer ? "hide" : "")
                    }
                >
                    <div style={{ padding: "0 20px" }}>
                        <FormGroup style={{ marginBottom: 5, marginTop: 15 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        {Config.lang("DHR_Hanh_dong")}
                                    </label>
                                    <Combo
                                        dataSource={dataActions}
                                        displayExpr={"actionName"}
                                        valueExpr={"action"}
                                        value={actions && actions}
                                        margin={"dense"}
                                        showClearButton
                                        stylingMode={"outlined"}
                                        placeholder={Config.lang("DHR_Hanh_dong")}
                                        onValueChanged={(e) => this.handleChange("actions", e)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup style={{ marginBottom: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        Module
                                    </label>
                                    <Combo
                                        dataSource={dataModule}
                                        displayExpr={"ModuleName"}
                                        valueExpr={"ModuleID"}
                                        value={modules && modules}
                                        margin={"dense"}
                                        stylingMode={"outlined"}
                                        showClearButton
                                        loading={loadingModule}
                                        placeholder={"Module"}
                                        onValueChanged={(e) => this.handleChange("modules", e)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup style={{ marginBottom: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        {Config.lang("DHR_Nghiep_vu")}
                                    </label>
                                    <Combo
                                        dataSource={dataCboTransaction}
                                        displayExpr={"TransactionName"}
                                        valueExpr={"TransactionID"}
                                        margin={"dense"}
                                        value={transactions && transactions}
                                        stylingMode={"outlined"}
                                        showClearButton
                                        disabled={loadingCboTransaction}
                                        placeholder={Config.lang("DHR_Kenh_tuyen_dung")}
                                        onValueChanged={(e) => this.handleChange("transactions", e)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup style={{ marginBottom: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        {Config.lang("DHR_Nhan_vien")}
                                    </label>
                                    <Combo.LoadMore
                                        dataSource={dataCboEmployees}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        displayExpr={"EmployeeName"}
                                        keyExpr={"UserID"}
                                        valueExpr={"UserID"}
                                        value={Employee && Employee}
                                        loading={cboEmployeeLoading}
                                        stylingMode={"outlined"}
                                        label={Config.lang("DHR_Nhan_vien")}
                                        itemRender={(e) => {
                                            const { data } = e;
                                            if (!data) return null;
                                            return data.UserID + " - " + data.EmployeeName;
                                        }}
                                        showClearButton={true}
                                        // showCheckBoxMode={"none"}
                                        onValueChanged={(e) => this.handleChange("Employee", e)}
                                        onInputChanged={(e) => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup style={{ marginBottom: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        {Config.lang("DHR_Mo_ta")}
                                    </label>
                                    <TextField
                                        placeholder={Config.lang("DHR_Nhap")}
                                        variant={"outlined"}
                                        onChange={(e) => this.handleChange("Content", e)}
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup style={{ marginBottom: 5 }}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label className={classes.titleAction + " mgb0"}>
                                        {Config.lang("DHR_Ngay_tac_dong")}
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <Typography className={classes.titleAction + " mgb0 pdr15"}>
                                            {Config.lang("DHR_Tu")}
                                        </Typography>
                                        <DateBoxPicker
                                            placeholder={"dd/mm/yyyy"}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={Datefrom}
                                            max={Dateto}
                                            width={"100%"}
                                            onValueChanged={(e) => this.handleChange("Datefrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <Typography className={classes.titleAction + " mgb0 pdr15"}>
                                            {Config.lang("DHR_Den")}
                                        </Typography>
                                        <DateBoxPicker
                                            placeholder={"dd/mm/yyyy"}
                                            min={Datefrom}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={Dateto}
                                            width={"100%"}
                                            onValueChanged={(e) => this.handleChange("Dateto", e)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup className="display_row align_center valign-bottom mgt25">
                            <ButtonGeneral
                                name={Config.lang("DHR_Tim_kiem")}
                                typeButton={"search"}
                                color={"primary"}
                                className={"pdl10 pdr10"}
                                variant={"outlined"}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                onClick={this.onFilter}
                            />
                        </FormGroup>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

W00F0100Nav.propTypes = {
    FormID: PropTypes.string,
    showDrawer: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOpen: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            historySearch: state.history.historySearch,
        }),
        (dispatch) => ({
            historyActions: bindActionCreators(historyActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
            w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch),
        })
    ),
    withStyles(styles)
)(W00F0100Nav);
