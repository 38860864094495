import React                 from "react";
import {withStyles}          from "@material-ui/core";
import {Col, FormGroup, Row} from 'react-bootstrap';
import Config                from '../../../../config';
import LabelText             from "../../../common/label-text/label-text";
import propType              from "prop-types"
import InputAdornment        from "@material-ui/core/InputAdornment";
import IconButton            from "@material-ui/core/IconButton";
import Icons                 from "../../../common/icons";
import W09F2200       from "../W09F2200/W09F2200";

const styles = {
    pdt :{
        paddingTop: '32px',
    },
    legend: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 24,
        border: "none"
    }
};

class W09F2000Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showW09F2200Popup: false,
            rowData: {}
        };
    }

    loadGrid = async () => {
        const {mode, onLoad} = this.props;
        if (onLoad) {
            await onLoad(mode, "", (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                this.setState({data: data});
            });
        }
    };

    componentDidMount = async () => {
        await this.loadGrid();
    };

    onEdit = (caption,oldPropertyID,fieldName, propertyID) => {
        const { employeeID } = this.props;
        this.setState({
            rowData: {
                caption,
                oldPropertyID,
                fieldName,
                propertyID,
                employeeID
            }
        });
        this.setState({showW09F2200Popup: true});
    };

    onCloseModal = () => {
        this.setState({showW09F2200Popup: false});
    };

    render() {
        const {classes, isHidden} = this.props;
        const {data, rowData, showW09F2200Popup} = this.state;

        return (
            <div style={{width: "100%"}}>
                {showW09F2200Popup && <W09F2200 open={showW09F2200Popup}
                                                onClose={() => this.onCloseModal()}
                                                mode={"edit"}
                                                rowData={rowData}/>}
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("DHR_Dien_thoai")}
                                value={Config.helpers.getObjectValue(data, 'Telephone', '')}
                                fullWidth={true}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={isHidden}
                                                onClick={() => this.onEdit(Config.lang("DHR_Dien_thoai"),
                                                    Config.helpers.getObjectValue(data, 'Telephone', ''),
                                                    "", "TEL")}>
                                                <Icons name={"edit"}/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("DHR_Di_dong")}
                                value={Config.helpers.getObjectValue(data, 'Mobile', '')}
                                fullWidth={true}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={isHidden}
                                                onClick={() => this.onEdit(Config.lang("DHR_Di_dong"),
                                                    Config.helpers.getObjectValue(data, 'Mobile', ''),
                                                    "", "MOBILE")}>
                                                <Icons name={"edit"}/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("DHR_Dien_thoai_noi_bo")}
                                value={Config.helpers.getObjectValue(data, 'CompanyTelephone', '')}
                                fullWidth={true}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={isHidden}
                                                onClick={() =>this.onEdit(Config.lang("DHR_Dien_thoai_noi_bo"),
                                                        Config.helpers.getObjectValue(data, 'CompanyTelephone', ''),
                                                     "", "CompanyTEL")}>
                                                <Icons name={"edit"}/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang("DHR_Email")}
                                value={Config.helpers.getObjectValue(data, 'Email', '')}
                                fullWidth={true}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={isHidden}
                                                onClick={() =>this.onEdit(Config.lang("DHR_Email"),
                                                    Config.helpers.getObjectValue(data, 'Email', '')
                                                    ,"", "EMAIL")}>
                                                <Icons name={"edit"}/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Dia_chi_lien_lac")}
                        value={Config.helpers.getObjectValue(data, 'ContactAddress', '')}
                        fullWidth={true}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("DHR_Dia_chi_lien_lac"),
                                            Config.helpers.getObjectValue(data, 'ContactAddress', '')
                                            ,"", "CONADDR")}>
                                        <Icons name={"edit"}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Dia_chi_thuong_tru")}
                        value={Config.helpers.getObjectValue(data, 'PermanentAddress', '')}
                        fullWidth={true}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={isHidden}
                                        onClick={() => this.onEdit(Config.lang("DHR_Dia_chi_thuong_tru"),
                                            Config.helpers.getObjectValue(data, 'PermanentAddress', ''),
                                            "", "PERADDR")}>
                                        <Icons name={"edit"}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Dia_chi_tam_tru")}
                        value={Config.helpers.getObjectValue(data, 'TemporaryAddress', '')}
                        fullWidth={true}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        className={isHidden}
                                        onClick={() =>this.onEdit(Config.lang("DHR_Dia_chi_tam_tru"),
                                            Config.helpers.getObjectValue(data, 'TemporaryAddress', ''),
                                            "","PROADDR")}>
                                        <Icons name={"edit"}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Nguoi lien he so 1*/}
                            <fieldset className={"mgt15"}>
                                <legend className={classes.legend}>{Config.lang("DHR_Nguoi_lien_he_so_1")}</legend>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Ho_va_ten")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactName1', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() =>this.onEdit(Config.lang("DHR_Ho_va_ten"),
                                                            Config.helpers.getObjectValue(data, 'EmContactName1', ''),
                                                            "", "EMCONNAME1")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Quan_he")}
                                        value={Config.helpers.getObjectValue(data, 'EmRelationName1', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() => this.onEdit(Config.lang("DHR_Quan_he"),
                                                            Config.helpers.getObjectValue(data, 'EmRelationName1', ''),
                                                            "EmRelationID", "EMCONREL1")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Dien_thoai")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactPhone1', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() => this.onEdit(Config.lang("DHR_Dien_thoai"),
                                                            Config.helpers.getObjectValue(data, 'EmContactPhone1', ''),
                                                            "", "EMCONPHONE1")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Dia_chi")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactAddress1', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() =>this.onEdit(Config.lang("DHR_Dia_chi"),
                                                            Config.helpers.getObjectValue(data, 'EmContactAddress1', ''),
                                                            "", "EMCONADD1")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                            </fieldset>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/*Nguoi lien he so 2*/}
                            <fieldset className={"mgt15"}>
                                <legend className={classes.legend}>{Config.lang("DHR_Nguoi_lien_he_so_2")}</legend>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Ho_va_ten")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactName2', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() => this.onEdit(Config.lang("DHR_Ho_va_ten"),
                                                            Config.helpers.getObjectValue(data, 'EmContactName2', ''),
                                                            "", "EMCONNAME2")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Quan_he")}
                                        value={Config.helpers.getObjectValue(data, 'EmRelationName2', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() => this.onEdit(Config.lang("DHR_Quan_he"),
                                                            Config.helpers.getObjectValue(data, 'EmRelationName2', ''),
                                                            "EmRelationID", "EMCONREL2")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Dien_thoai")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactPhone2', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() =>this.onEdit(Config.lang("DHR_Dien_thoai"),
                                                            Config.helpers.getObjectValue(data, 'EmContactPhone2', ''),
                                                            "","EMCONPHONE2")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <LabelText
                                        label={Config.lang("DHR_Dia_chi")}
                                        value={Config.helpers.getObjectValue(data, 'EmContactAddress2', '')}
                                        fullWidth={true}
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className={isHidden}
                                                        onClick={() =>this.onEdit(Config.lang("DHR_Dia_chi"),
                                                            Config.helpers.getObjectValue(data, 'EmContactAddress2', ''),
                                                            "", "EMCONADD2")}>
                                                        <Icons name={"edit"}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormGroup>
                            </fieldset>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        );
    }

}

W09F2000Personal.propType = {
    data : propType.any,
};

export default withStyles(styles)(W09F2000Personal)
