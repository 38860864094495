import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 15,
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: '#F3F3F3'
    },
    labelCheckbox: {
        fontSize: '0.8rem'
    },
    checkbox: {
        padding: '0 4px'
    },
    tab: {
        maxWidth: 'fit-content',
        padding: '15px 58px'
    },
    tabActive: {
        backgroundColor: '#fff',
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    }
  }));