/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/5/2021
 * @Example
 */

import { withStyles } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import Config from '../../../../config';
import LabelText from '../../../common/label-text/label-text';

const styles = (theme) => {
    return {
        legend: {
            color: theme.palette.primary.main,
            fontWeight: 500,
            fontSize: 16,
            textTransform: 'uppercase',
            marginTop: 24,
            border: 'none',
        },
        notHavePermission: {
            width: '100%',
            fontSize: 17,
            textAlign: 'center'
        }
    };
};

const W09F2000Insurrance = forwardRef((props, ref) => {
    const { mode, onLoad, classes, permission } = props;

    const [data, setData] = useState({});

    const loadGrid = useCallback(async () => {
        if (onLoad) {
            await onLoad(mode, '', (error, dt) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
                permission && setData(dt);
            });
        }
    }, [mode, onLoad, permission]);

    useEffect(() => {
        loadGrid();
    }, [loadGrid]);

    return (
        permission ? <div style={{ width: '100%' }}>
            <fieldset className={'mgt15'}>
                <legend className={classes.legend}>
                    {Config.lang('Bao_hiem_xa_hoi')}
                </legend>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('So_so_BHXH')}
                                value={data?.SocInsBookNo ?? ''}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Ngay_tham_gia_BHXH')}
                                value={
                                    data?.SIBeginDate
                                        ? moment(data?.SIBeginDate).format(
                                              'DD-MM-YYYY'
                                          )
                                        : ''
                                }
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Ngay_cap_BHXH')}
                                value={
                                    data?.SocInsIssueDate
                                        ? moment(data?.SocInsIssueDate).format(
                                              'DD-MM-YYYY'
                                          )
                                        : ''
                                }
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Noi_cap_BHXH')}
                                value={data?.SocInsIssuePlace ?? ''}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </fieldset>
            <fieldset className={'mgt15'}>
                <legend className={classes.legend}>
                    {Config.lang('Bao_hiem_y_te')}
                </legend>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('So_the_BHYT')}
                                value={data?.HealthInsBookNo ?? ''}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Ngay_dang_ky_KCB')}
                                value={
                                    data?.HealthInsIssueDate
                                        ? moment(
                                              data?.HealthInsIssueDate
                                          ).format('DD-MM-YYYY')
                                        : ''
                                }
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Phieu_KCB_tu_ngay')}
                                value={
                                    data?.HealthInsStart
                                        ? moment(data?.HealthInsStart).format(
                                              'DD-MM-YYYY'
                                          )
                                        : ''
                                }
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Phieu_KCB_den_ngay')}
                                value={
                                    data?.HealthInsEnd
                                        ? moment(data?.HealthInsEnd).format(
                                              'DD-MM-YYYY'
                                          )
                                        : ''
                                }
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Noi_dang_ky_KCB')}
                                value={data?.HealthInsIssuePlace ?? ''}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                label={Config.lang('Benh_vien_KCB')}
                                value={data?.HospitalName ?? ''}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </fieldset>
        </div> : <div className={classes.notHavePermission}>{Config.lang('Du_lieu_ca_nhan,_ban_khong_co_quyen_xem')}</div>
    );
});

W09F2000Insurrance.propTypes = {
    mode: PropTypes.number,
    onLoad: PropTypes.func,
    classes: PropTypes.object,
    permission: PropTypes.bool,
};

W09F2000Insurrance.defaultProps = {
    mode: 11,
    onLoad: null,
    classes: {},
    permission: false
};

export default withStyles(styles, { withTheme: true })(W09F2000Insurrance);
