import React, {useState, useRef, useEffect} from "react";
import {withStyles} from "@material-ui/core";
import {Col, Row, FormGroup} from 'react-bootstrap';
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import _ from "lodash";
import {Tooltip} from "diginet-core-ui/components";

const styles = (theme) => {
    return ({
        insSalaryContainer: {
            display: "flex",
            alignItems: "center",
            width: "100%"
        },
        insSalaryEmployeeRate: {
            width: 60,
            height: 20,
            backgroundColor: theme.palette.info.main,
            textAlign: "center",
            margin: "0px 4px"
        },
        insSalaryEmployeerRate: {
            width: 60,
            height: 20,
            backgroundColor: theme.palette.info.light,
            textAlign: "center",
            margin: "0px 4px"
        },
        insSalaryRateTooltipNLD: {
            backgroundColor: theme.palette.info.light,
            height: 15,
            width: 25
        },
        insSalaryRateTooltipNSDLD: {
            backgroundColor: theme.palette.info.main,
            height: 15,
            width: 25
        }

    })
}

const W09F2000HistorySocialInsurance = ({mode, onLoad, classes}) => {
    const [dataHistory, setDataHistory] = useState({rows: [], total: 0});
    const paging = useRef({skip: 0, limit: 4});
    let {skip, limit} = paging.current;

    useEffect(() => {
        loadGrid();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadGrid = async () => {
        await onLoad(mode, "", (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = _.get(data, "rows", data);
            const total = _.get(data, "total", _.size(data))
            setDataHistory({rows, total});
        }, skip, limit);
    };

    const handleChangePage = (page) => {
        skip = page * limit;
        loadGrid()
    };

    const handleChangePerPage = (perPage) => {
        skip = 0;
        limit = perPage;
        loadGrid()
    };

    const handleStringsConcatenation = (characters = "", ...value) => _.join(_.compact(value), characters);

    const renderPeriodOfInsPar = ({data}) => handleStringsConcatenation(" - ", data?.FromMonthYear, data?.ToMonthYear)

    const renderTimeToPayIns = ({data}) => handleStringsConcatenation(
        ", ",
        !!data?.SumYear && `${data.SumYear} ${Config.lang("Nam")}`,
        !!data?.SumMonth && `${data.SumMonth} ${Config.lang("Thang")}`
    )

    const renderSalaryAndAllowance = ({data}) => <div dangerouslySetInnerHTML={{__html: data?.SubSalary || ""}}/>

    const renderInsSalary = ({data}) => {
        const arrInsSalary = _.filter([
            {
                title: Config.lang("BHXH"),
                amount: data?.SIAmount || "",
                employeeRate: data?.EmployeeRate || "",
                employerRate: data?.EmployeerRate || ""
            },
            {
                title: Config.lang("BHYT"),
                amount: data?.HIAmount || "",
                employeeRate: data?.EmployeeRate2 || "",
                employerRate: data?.EmployeerRate2 || ""
            },
            {
                title: Config.lang("BHTN"),
                amount: data?.UIAmount || "",
                employeeRate: data?.EmployeeRate3 || "",
                employerRate: data?.EmployeerRate3 || ""
            }
        ], ({amount, employeeRate, employerRate}) => !!amount || !!employeeRate || !!employerRate);
        return _.map(arrInsSalary, (item, i) => {
                const amount = item.amount;
                const employeeRate = item.employeeRate;
                const employerRate = item.employerRate;
                return <FormGroup key={i}>
                    <div className={classes.insSalaryContainer} style={{overflowX: 'scroll'}}>
                        {/*<span style={{paddingRight: 5}}>{item.title}: </span>*/}
                        {/*{*/}
                        {/*    amount && <span>{amount}</span>*/}
                        {/*}*/}
                        <span>
                            {_.join(_.compact([item.title, amount.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})]), ":  ")}
                        </span>
                        <div style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                            {i !== 2 ? <Tooltip
                                title={<div className={classes.insSalaryRateTooltip}>
                                    <div style={{display: "flex"}}>
                                        <div className={classes.insSalaryRateTooltipNLD}/>
                                        <span style={{paddingLeft: 8}}>{Config.lang("NLD")}</span>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div className={classes.insSalaryRateTooltipNSDLD}/>
                                        <span style={{paddingLeft: 8}}>{Config.lang("NSDLD")}</span>
                                    </div>
                                </div>}
                                arrow
                                direction={"down"}>
                                <div className={classes.insSalaryContainer}>
                                    {
                                        employeeRate &&
                                        <div className={classes.insSalaryEmployeeRate}>
                                            <span>{employeeRate + " %"}</span>
                                        </div>
                                    }
                                    {
                                        employerRate && <div className={classes.insSalaryEmployeerRate}>
                                            <span>{employerRate + " %"}</span>
                                        </div>
                                    }
                                </div>
                            </Tooltip> : ""}
                        </div>
                    </div>
                </FormGroup>
            }
        )
    }

    return <div style={{width: "100%"}}>
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                <GridContainer
                    dataSource={dataHistory.rows}
                    totalItems={dataHistory.total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    onChangePage={handleChangePage}
                    onChangePerPage={handleChangePerPage}
                    pagerFullScreen={false}
                    typePaging={"remote"}
                    loading={false}
                >
                    <Column
                        width={220}
                        caption={Config.lang("Thoi_gian_tham_gia_BH")}
                        cellRender={renderPeriodOfInsPar}
                    />
                    <Column caption={Config.lang("Don_vi_da_lam_viec")} dataField={"OldDivision"}/>
                    <Column caption={Config.lang("Cong_viec")} dataField={"OldDutyName"}/>
                    <Column alignment={"center"}
                            width={220}
                            caption={Config.lang("Thoi_gian_dong_BHXH")}
                            cellRender={renderTimeToPayIns}/>
                    <Column caption={Config.lang("Muc_luong_va_phu_cap")}
                            cellRender={renderSalaryAndAllowance}
                    />
                    <Column
                        width={340}
                        caption={Config.lang("Luong_dong_bao_hiem")}
                        cellRender={renderInsSalary}
                    />
                    <Column
                        caption={Config.lang("Ghi_chu")}
                        dataField={"Note"}
                    />
                </GridContainer>
            </Col>
        </Row>
    </div>
};

export default withStyles(styles, {withTheme: true})(W09F2000HistorySocialInsurance);