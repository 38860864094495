import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { browserHistory } from "react-router";

import withStyles from "@material-ui/core/styles/withStyles";
import { Row, Col, FormGroup } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";

import * as generalActions from "../../../../redux/general/general_actions";
import * as w09f1500Actions from "../../../../redux/W0X/W09F1500/W09F1500_actions";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import UserImage from "../../../common/user/user-image";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";

const styles = {
  divAvatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: 8
  },
  imgAvatar: {
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    objectFit: "cover"
  },
  chip: {
    fontSize: "1rem",
    fontWeight: 400,
    border: "none",
    backgroundColor: "#EBEBEB"
  },
  dateInfo: {
    display: "flex",
    alignItems: "center"
  },
  divText: {
    // fontSize: "14px",
    fontWeight: 500,
    // color: "#575757"
  },
  divDateText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000"
  },
  colActions: {
    height: '68px !important'
  }
};

class W09F1500 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iPermission: 0,
      selectedRowKeys: [],
      gridLoading: false,
      DateFrom: null,
      DateTo: null,
      dataCboProjects: {
        rows: [],
        total: 0
      },
      loading: {
        cboProjects: false,
      },
    };
    this.loading = {
      cboProjects: false,
    };
    this.filter = {
      Project: null,
      DutyID: "",
      DepartmentID: "",
      DirectManagerID: "",
      searchvalue: "",
      EmpStatus: "",
      fieldSort: "",
      typeSort: "",
      skip: 0,
      limit: 10
    };
    this.timer = null;
    this.filterCboProject = {
      strSearch: "",
      skip: 0,
      limit: 50
    };
  }

  loadPermission = async () => {
    await this.props.generalActions.getPermission("W09F1500", isPer => {
      this.setState({ iPermission: isPer });
    });
  };

  loadCboAppStatus = () => {
    const param = {
      FormID: "W09F1500",
      Language: Config.language || 84
    };
    this.props.generalActions.getCboAppStatus(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadCboProjects = (isReset) => {
    const param = {
      HostID: "",
      FormID: "W09F1500",
      Language: Config.language || "84",
      skip: this.filterCboProject.skip,
      limit: this.filterCboProject.limit,
      search: this.filterCboProject.strSearch
    };
    this.props.generalActions.getCboProjects(param, (error, data) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboProjects} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboProjects: {
            rows: isReset ? rows : dataCboProjects.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadCboDepartment = () => {
    const param = {
      HostID: "",
      FormID: "W09F1500",
      DivisionID: Config.getDivisionID(),
      Language: Config.language || "84"
    };

    this.props.generalActions.getCboDepartments(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadDataGrid = () => {
    const { DateFrom, DateTo } = this.state;
    const {DepartmentID, DirectManagerID, DutyID,EmpStatus, Project, searchvalue, fieldSort, typeSort, limit, skip} = this.filter;
    const params = {
      FormID: "W09F1500",
      HostID: "",
      Language: Config.language || 84,
      DateFrom,
      DateTo,
      DepartmentID,
      DirectManagerID,
      DutyID,
      EmpStatus,
      ProjectID: Project && Project.ProjectID ? Project.ProjectID : "",
      fieldSort,
      typeSort,
      searchvalue,
      limit,
      skip
    };
    this.setState({ gridLoading: true });
    this.props.w09f1500Actions.getDataGrid(params, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ gridLoading: false });
    });
  };

  loadDirectManager = () => {
    const params = {
      FormID: "W09F1500",
      DivisionID: Config.getDivisionID(),
      HostID: ""
    };
    this.setState({ gridLoading: true });
    this.props.w09f1500Actions.getCboDirectManagers(params, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ gridLoading: false });
    });
  };

  loadCboDuty = () => {
    const params = { Language: Config.language || "84" };
    this.setState({ gridLoading: true });
    this.props.generalActions.getCboDuty(params, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ gridLoading: false });
    });
  };

  componentDidMount = async () => {
    await this.loadPermission();
    if (!this.state.iPermission) return;
    this.loadDataGrid();
    this.loadDirectManager();
    this.loadCboProjects();
    this.loadCboAppStatus();
    this.loadCboDuty();
    this.loadCboDepartment();
  };

  filterChange(key, data) {
    if (!key) return false;
    const value = _.get(data, "value", "");
    switch (key) {
      case "DateFrom":
        this.setState({ DateFrom: value });
        break;
      case "DateTo":
        this.setState({ DateTo: value });
        break;
      case "AppStatusID":
        this.filter.EmpStatus = value;
        break;
      case "DutyID":
        this.filter.DutyID = value;
        break;
      case "DirectManagerID":
        this.filter.DirectManagerID = value;
        break;
      case "DepartmentID":
        this.filter.DepartmentID = value;
        break;
      case "ProjectID":
        this.filter.Project = value;
        break;
      default:
        break;
    }
  }

  onSearch = () => {
    this.loadDataGrid();
  };

  onSearchText = e => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.filter.searchvalue = _.get(e, "target.value", "");
      this.loadDataGrid();
    }, 500);
  };

  redirectScreen = (mode, e) => {
    const TransID = _.get(e, "data.TransID", "");
    browserHistory.push({
      pathname: Config.getRootPath() + "W09F1501",
      state: {
        mode: mode,
        TransID: TransID
      }
    });
  };

  onDelete = data => {
    const TransID = _.get(data, "TransID", "");
    const param = {
      TransID
    };
    Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_muon_xoa?"), () => {
      this.props.w09f1500Actions.deleteDataGrid(param, error => {
        if (error) {
          Config.popup.show("INFO", Config.lang("DHR_Loi_chua_xac_dinh"));
          return false;
        } else {
          Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 3000);
          this.loadDataGrid();
        }
      });
    });
  };

  onChangePage = page => {
    this.filter.skip = page * this.filter.limit;
    this.loadDataGrid();
  };

  onChangePerPage = perPage => {
    this.filter.skip = 0;
    this.filter.limit = perPage;
    this.loadDataGrid();
  };

  onOrderChanged = (e) => {
    if (!e || !e.column) return false;
    this.filter.fieldSort = e.column.dataField;
    this.filter.typeSort = e.typeSort;
    this.loadDataGrid();
  };

  renderAvatar = e => {
    const data = _.get(e, "data", "");
    return (
      <div className={"display_row align-center"}>
        <UserImage data={data} width={32} height={32} />
      </div>
    );
  };

  renderFilter = () => {
    const {
      getCboDuty,
      getCboDepartments,
      getCboAppStatus,
      getCboDirectManagers,
      classes
    } = this.props;
    const { Project, DepartmentID, DirectManagerID, EmpStatus, DutyID } = this.filter;
    const { dataCboProjects, loading, DateFrom, DateTo, iPermission } = this.state;
    return (
      <Filter
        showClearButton={true}
        onTextChanged={this.onSearchText}
        placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
        openOnFieldClick={true}
        renderFilter={() => {
          return (
            <>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>{Config.lang("DHR_Tu")}</div>
                      <DateBoxPicker
                        dateBoxProps={{ max: DateTo }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("DateFrom", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={DateFrom || null}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>
                        {Config.lang("DHR_Den").toLowerCase()}
                      </div>
                      <DateBoxPicker
                        dateBoxProps={{ min: DateFrom }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("DateTo", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={DateTo || null}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Trang_thai_duyet")}
                      dataSource={getCboAppStatus}
                      displayExpr={"AppStatusName"}
                      valueExpr={"AppStatusID"}
                      onValueChanged={e => this.filterChange("AppStatusID", e)}
                      showClearButton={true}
                      value={EmpStatus || ""}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Chuc_vu")}
                      dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                      displayExpr={"ProDutyName"}
                      valueExpr={"ProDutyID"}
                      onValueChanged={e => this.filterChange("DutyID", e)}
                      showClearButton={true}
                      value={DutyID || ""}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboProjects}
                        skip={this.filterCboProject.skip}
                        limit={this.filterCboProject.limit}
                        displayExpr={'ProjectName'}
                        keyExpr={'ProjectID'}
                        valueExpr={'ProjectID'}
                        value={Project}
                        loading={loading.cboProjects}
                        stylingMode={'outlined'}
                        label={Config.lang('DHR_Du_an')}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.ProjectID + ' - ' + data.ProjectName;
                        }}
                        showClearButton={true}
                        // showCheckBoxMode={"none"}
                        onValueChanged={e => this.filterChange("ProjectID", e)}
                        onInputChanged={(e) => {
                          this.filterCboProject.strSearch = e.target.value;
                          this.filterCboProject.skip = 0;
                          this.loadCboProjects(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboProject.skip = e.skip;
                          this.filterCboProject.limit = e.limit;
                          this.loadCboProjects();
                        }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Phong_ban")}
                      dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                      valueExpr={"DepartmentID"}
                      displayExpr={"DepartmentName"}
                      onValueChanged={e => this.filterChange("DepartmentID", e)}
                      showClearButton={true}
                      value={DepartmentID || ""}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("DHR_Nguoi_quan_ly_truc_tiep")}
                      dataSource={Config.storeDataSoureDevExtreme(getCboDirectManagers)}
                      valueExpr={"DirectManagerID"}
                      displayExpr={"DirectManagerName"}
                      onValueChanged={e => this.filterChange("DirectManagerID", e)}
                      showClearButton={true}
                      value={DirectManagerID || ""}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "38px",
                  paddingBottom: "40px"
                }}
              >
                <ButtonGeneral
                    disabled={!(iPermission >= 1)}
                    name={Config.lang("DHR_Tim_kiem")}
                    typeButton={"search"}
                    size={"large"}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={this.onSearch}
                />
              </div>
            </>
          );
        }}
      />
    );
  };

  renderStatus = e => {
    const { getCboAppStatus } = this.props;
    const dataRow = Number(_.get(e, "data.EmpStatus", false));
    const statusEmp = _.get(getCboAppStatus[dataRow], "AppStatusID", 0);
    const statusEmpName = _.get(getCboAppStatus[dataRow], "AppStatusName", "");
    const color = statusEmp !== 0 ? "primary" : "";
    const status = {
      AppStatusID: statusEmp,
      AppStatusName: statusEmpName ? statusEmpName : Config.lang("DHR_Khong_trang_thai"),
      AppStatusColor: color
    };

    return <Status data={status} />;
  };

  renderAction = e => {
    const { iPermission } = this.state;
    const data = _.get(e, "data", "");
    const empStatus = Number(data.EmpStatus) === 0;
    const allowEdit = iPermission >= 1;
    const allowDelete = iPermission >= 4;
    return (
        <GridActionBar>
          <div className="">
            <IconButton
              disabled={!empStatus ? true : !allowEdit}
              aria-label="view"
              style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
              onClick={() => this.redirectScreen("edit", e)}
            >
              <Icons name={"edit"}/>
            </IconButton>
            <IconButton
              disabled={!empStatus ? true : !allowDelete}
              aria-label="delete"
              style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
              onClick={() => this.onDelete(data)}
            >
              <Icons name={"delete"}/>
            </IconButton>
          </div>
        </GridActionBar>
    );
  };
  render() {
    const { gridLoading, selectedRowKeys, iPermission } = this.state;
    if (iPermission <= 0) return null;
    const { skip, limit } = this.filter;
    const { getDataGrid } = this.props;
    const dataRows = _.get(getDataGrid, "rows", []);
    const totalRow = _.get(getDataGrid, "total", 0);
    return (
      <FormGroup>
        <ActionToolbar title={Config.lang("DHR_Truy_van_tang_nhan_vien")}>
          <ButtonGeneral
            name={Config.lang("DHR_Them")}
            typeButton={"add"}
            style={{ textTransform: "uppercase" }}
            size={"large"}
            onClick={() => this.redirectScreen()}
            disabled={!(iPermission >= 2)}
          />
        </ActionToolbar>
        <div className={"hidden"}>{this.renderFilter()}</div>
        <FormGroup>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
              <GridContainer
                itemPerPage={limit}
                skipPerPage={skip}
                onDbCellClick={e => this.redirectScreen("view", e)}
                loading={gridLoading}
                selectedRowKey={selectedRowKeys}
                style={{ border: "none" }}
                dataSource={dataRows}
                showRowLines={false}
                isOrderAPI={true}
                showBorders={false}
                typePaging={"remote"}
                totalItems={totalRow}
                selection={{
                  mode: "single"
                }}
                height={Config.getHeightGrid()}
                typeShort={window.innerWidth < 768}
                showColumnLines={false}
                hoverStateEnabled={true}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}
                onOrderChanged={this.onOrderChanged}
              >
                <Column cellRender={this.renderAvatar} />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Ma_NV")}
                  dataField={"EmployeeID"}
                  alignment={"left"}
                  minWidth={150}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Ten_nhan_vien")}
                  dataField={"EmployeeName"}
                  alignment={"left"}
                  minWidth={150}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Gioi_tinh")}
                  dataField={"SexName"}
                  alignment={"center"}
                  minWidth={110}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_So_dien_thoai")}
                  dataField={"Telephone"}
                  alignment={"center"}
                  minWidth={140}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Chuc_vu")}
                  dataField={"DutyName"}
                  alignment={"left"}
                  minWidth={120}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Du_an")}
                  dataField={"ProjectName"}
                  alignment={"left"}
                  minWidth={100}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Phong_ban")}
                  dataField={"DepartmentName"}
                  alignment={"left"}
                  minWidth={120}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Nguoi_quan_ly_truc_tiep")}
                  dataField={"DirectManagerName"}
                  alignment={"left"}
                  minWidth={220}
                />
                <Column
                  style={{ backgroundColor: "#b3bbc1" }}
                  caption={Config.lang("DHR_Ngay_vao")}
                  dataField={"DateJoined"}
                  alignment={"center"}
                  dataType={"date"}
                  format={"dd/MM/yyyy"}
                  minWidth={120}
                />
                <Column
                  caption={Config.lang("DHR_Xac_nhan")}
                  alignment={"center"}
                  cellRender={this.renderStatus}
                  minWidth={120}
                />
                <Column
                  alignment={"right"}
                  fixed={true}
                  fixedPosition={"right"}
                  width={150}
                  visible={!Config.isMobile}
                  cellRender={this.renderAction}
                />
              </GridContainer>
            </Col>
          </Row>
        </FormGroup>
      </FormGroup>
    );
  }
}
export default compose(
  connect(
    state => ({
      getCboDuty: state.general.getCboDuty,
      getCboDepartments: state.general.getCboDepartments,
      getCboAppStatus: state.general.getCboAppStatus,
      getCboDirectManagers: state.W09F1500.getCboDirectManagers,
      getDataGrid: state.W09F1500.getDataGrid
    }),
    dispatch => ({
      w09f1500Actions: bindActionCreators(w09f1500Actions, dispatch),
      generalActions: bindActionCreators(generalActions, dispatch)
    })
  ),
  withStyles(styles)
)(W09F1500);
