/**
 * @copyright 2020 @ DigiNet
 * @author HUYTRAN
 * @create 10/30/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as historyActions from "../../../../redux/history/history_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { FormGroup } from "react-bootstrap";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import { Typography, IconButton, Drawer } from "@material-ui/core";
import Icons from "../../../common/icons";
import Status from "../../../common/status/status";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";

const paperWidth = "33%";
const styles = (theme) => ({
    divIcon: {
        width: 30,
        height: 30,
    },
    headerToolbar: {
        height: 50,
        top: 0,
        width: "100%",
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
    },
    hide: {
        display: "none",
    },
    viewDetail: {
        color: "#7F828E",
        cursor: "pointer",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    drawer: {
        width: paperWidth,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: paperWidth,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        top: 50,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
    },
});
class W00F0100Detail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }

    _renderAction = (e) => {
        const { data } = e.row;
        return <div>{this._renderStatus(data)}</div>;
    };
    _renderStatus = (data) => {
        const { classes } = this.props;
        let actions = {
            added: {
                color: "success",
                action: Config.lang("DHR_Them_moi1"),
                icons: "add",
            },
            edited: {
                color: "info",
                action: Config.lang("DHR_Sua"),
                icons: "edit",
            },
            removed: {
                color: "warning",
                action: Config.lang("DHR_Xoa"),
                icons: "delete",
            },
        };
        if (!_.isEmpty(data)) {
            let status = {
                AppStatusName: actions[data.action].action,
                AppStatusColor: actions[data.action].color,
                AppStatusIcon: (
                    <Icons
                        name={actions[data.action].icons}
                        color={actions[data.action].color}
                    />
                ),
            };
            return <Status data={status} className={classes.statusText} />;
        }
    };

    checkIsZero = (number) => {
        if (number || number === 0) return true;
        return false;
    }

    _renderContent = (e) => {
        const { data } = e.row;
        return (
            <div>
                {Object.keys(data).map((item, idx) => {
                    if (item !== "action" && item.substring(0, 4) !== "data") {
                        if (_.isObject(data[item]) && !_.isUndefined(data[item]["old"])) {
                            let oldVal = this.checkIsZero(data[item]["old"]) ? data[item]["old"] : "",
                                newVal = this.checkIsZero(data[item]["new"]) ? data[item]["new"] : "";
                            return (
                                <div className="display_row align-center" key={idx}>
                                    {`${Config.lang(item)}: ${oldVal} -> ${newVal}`}
                                </div>
                            );
                        } else {
                            return (
                                <div className="display_row align-center" key={idx}>
                                    {`${Config.lang(item)}: ${this.checkIsZero(data[item]) ? data[item] : ""}`}
                                </div>
                            );
                        }
                    }
                    return null;
                })}
            </div>
        );
    };
    loadDataDetail = () => {
        const { dataDetail } = this.props;
        let newArr = [];
        if (dataDetail && !_.isEmpty(dataDetail.value)) {
            Object.keys(dataDetail.value).map((item) => {
                dataDetail.value[item].map((ii) => {
                    ii["action"] = item;
                    return ii;
                });
                newArr.push(dataDetail.value[item]);
                return item;
            });
        }
        this.setState({ dataSource: newArr.flat(1) });
    };
    componentDidUpdate(prevProps) {
        if (prevProps.showPopupDetail !== this.props.showPopupDetail) {
            this.loadDataDetail();
        }
    }

    onClose = () => {
        const { handleClose } = this.props;
        this.setState({ dataSource: [] });
        if (handleClose) handleClose();
    };
    render() {
        const { classes, showPopupDetail, dataDetail } = this.props;
        const { dataSource } = this.state;
        return (
            <React.Fragment>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={showPopupDetail}
                    onClose={this.onClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        BackdropProps: {
                            invisible: true,
                        },
                    }}
                >
                    <div className={classes.headerToolbar}>
                        <Typography variant="h6">
                            {dataDetail ? dataDetail.title : ""}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.divIcon}
                            onClick={this.onClose}
                            size={"medium"}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {/* <Divider style={{ marginLeft: -15, marginRight: -15 }} /> */}
                    {/* className="display_row row" style={{ flexDirection: "row" }} */}
                    <FormGroup>
                        <GridContainer
                            dataSource={dataSource}
                            showBorders={false}
                            height={Config.getHeightGrid()}
                            listPerPage={[15, 30, 45, 60]}
                            typePaging={"normal"}
                            hoverStateEnabled={true}
                            showColumnHeaders={false}
                            rowAlternationEnabled={false}
                        // showRowLines={false}
                        >
                            <Column
                                caption={Config.lang("DHR_Mo_ta")}
                                alignment={"left"}
                                minWidth={250}
                                cellRender={this._renderContent}
                            />
                            <Column
                                caption={Config.lang("DHR_Hanh_dong")}
                                alignment={"left"}
                                width={150}
                                cellRender={this._renderAction}
                            />
                        </GridContainer>
                    </FormGroup>
                </Drawer>
            </React.Fragment>
        );
    }
}

W00F0100Detail.propTypes = {
    onClose: PropTypes.func,
    showPopupDetail: PropTypes.bool,
};

export default compose(
    connect(
        (state) => ({
            historySearch: state.history.historySearch,
        }),
        (dispatch) => ({
            historyActions: bindActionCreators(historyActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
    withStyles(styles)
)(W00F0100Detail);
