/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/1/2019
 * @Example
 */

import React, {useEffect, useRef, useState} from "react";
import Config                               from "../../../../config";
import PropTypes                            from "prop-types";
import {bindActionCreators, compose}        from "redux";
import {connect}                            from "react-redux";
import * as w00f0010Actions                 from "../../../../redux/W0X/W00F0010/W00F0010_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import {FormGroup, Row, Col}                from "react-bootstrap";
import {
    FormLabel as Label,
    Card,
    CardContent, Tabs, Tab, Checkbox as CheckboxM
}                                    from "@material-ui/core";
import {TextField, Combo, MForm, Checkbox} from "../../../common/form-material/";
import {Column, RequiredRule} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import TabContent, {TabPanel} from "../../../common/tabs/tab-content";
import Modal from "../../../common/modal/modal";
import W00F0010RolePermission from "./W00F0010RolePermission";
import ButtonGeneral from "../../../common/button/button-general";
import Skeleton             from "@material-ui/lab/Skeleton";
import moment               from "moment";
import EditIcon             from "@material-ui/icons/Edit";
import SaveIcon             from '@material-ui/icons/Save';
import UndoIcon             from '@material-ui/icons/Undo';
import IconButton           from "@material-ui/core/IconButton";
import {useStyles}          from "./styles";
import * as _               from "lodash";

const W00F0010RoleInfo = React.memo(React.forwardRef((props, ref) => {
    const _initData = {
        RoleID: '',
        RoleName: '',
        Company: [],
        IsSpecifiedData: false,
        IsManager: false,
    };
    const classes = useStyles();
    const {roleID, listRolesRef, getCompany, getCboUserRoles} = props;
    const [loading, _setLoading] = useState({main: false, gridPermission: false, gridUser: false});
    const [tabKey, setTabKey] = useState(0);
    const [dataPermissionInfo, setDataPermissionInfo] = useState([]);
    const [dataUsersInfo, setDataUsersInfo] = useState([]);
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disabledCompany, setDisabledCompany] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errorHelperText, setErrorHelperText] = useState({RoleID: ""});
    const [showEditPermission, setShowEditPermission] = useState(false);
    const [getRoleInfo, setGetRoleInfo] = useState({});
    const [modeUserRoles, setModeUserRoles] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [dataForm, _setDataForm] = useState(_initData);

    const mode = useRef("");
    const dataGridUserRole = useRef(null);
    const permissionInfo = useRef(null);
    const filterPermission = useRef({limit: 10, skip: 0});
    const filterColIndx = useRef(-1);
    const eRow = useRef(null);

    //Init function..
    const setLoading = (obj) => {
        _setLoading({...loading, ...obj});
    };

    const setStateErrorText = (objValue) => {
        if (objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setData = (obj) => {
        _setDataForm({...dataForm, ...obj});
    };

    ////////////////////////////////////////////////////
    //Load..
    const loadCboUserRoles = () => {
        props.w00f0010Actions.getCboUserRolesW00F0010((error) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    const loadCboCompany = () => {
        if (!roleID) return false;
        const param = {
            RoleID: roleID,
            Language: Config.language || '84'
        };
        props.w00f0010Actions.getCompany(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    const loadRoleInfo = () => {
        if (!roleID) return false;
        const param = {
            RoleID: roleID,
            Language: Config.language || '84'
        };
        setLoading({main: true});
        props.w00f0010Actions.getRoleInfo(param, (error, data) => {
            setLoading({main: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                let Company = [];
                if (data.StrCompanyID) {
                    const listCompany = data.StrCompanyID.replace(/,/g, ';').split(";");
                    listCompany.forEach((e) => {
                        Company.push(e);
                    });
                }
                setGetRoleInfo(data);
                setChecked(!!data.Disabled);
                setDisabled(true);
                setIsUpdate(false);
                setData({
                    RoleID: data.RoleID || "",
                    RoleName: data.RoleName || "",
                    Company: Company,
                    IsSpecifiedData: !!data.IsSpecifiedData,
                    IsManager: !!data.IsManager,
                });
            }
        });
    };

    const loadGridPermissionInfo = () => {
        if (!roleID) return false;
        const param = {
            RoleID: roleID,
            Language: Config.language || '84'
        };
        setLoading({gridPermission: true});
        props.w00f0010Actions.getPermissionInfo(param, (error, data) => {
            setLoading({gridPermission: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                setDataPermissionInfo(data);
            }
        });
    };

    const loadGridUsersInfo = () => {
        if (!roleID) return false;
        const param = {
            RoleID: roleID,
            Language: Config.language || '84'
        };
        setLoading({gridUser: true});
        props.w00f0010Actions.getUsersInfo(param, (error, data) => {
            setLoading({gridUser: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                setDataUsersInfo(data);
            }
        });
    };
    //End load..
    /////////////////////////////////////////////
    // const onLoadDataFilter = () => {
    //     loadCboDuty();
    //     loadCboEmployees();
    //     loadCboProjects();
    // };

    useEffect(loadCboUserRoles, []);

    useEffect(() => {
        if (isUpdate) {
            setIsUpdate(false);
            onNotSave();
        }
        loadGridPermissionInfo();
        loadGridUsersInfo();
        loadCboCompany();
        loadRoleInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleID]);

    useEffect(() => {
        Object.keys(dataForm).forEach(key => {
            if (dataForm[key]) setStateErrorText({[key]: ""});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);

    useEffect(() => {
        if (isEditing) {
            if (modeUserRoles === "add") {
                setTimeout(() => {
                    if (dataGridUserRole.current) {
                        dataGridUserRole.current.instance.addRow();
                    }
                }, 300);
            } else if (eRow.current) {
                setTimeout(() => {
                    if (dataGridUserRole.current) {
                        const {rowIndex} = eRow.current || {};
                        dataGridUserRole.current.editRow(rowIndex);
                    }
                }, 300);
            }
        }
    }, [isEditing, modeUserRoles]);

    ////////////////////////////////////////////
    //Event...
    const handleChange = (name, e, data) => {
        console.log("testchange");
        switch (name) {
            case "RoleID":
                setData({RoleID: e.target.value});
                break;
            case "RoleName":
                setData({RoleName: e.target.value});
                break;
            case "Company":
                setData({Company: e.value});
                break;
            case "KSD":
                setChecked(!!data);
                break;
            case "Tab":
                setTabKey(data);
                break;
            case "IsSpecifiedData":
                setData({IsSpecifiedData: !!data});
                break;
            case "IsManager":
                setData({IsManager: !!data});
                break;
            default:
                console.log('no change');
                break;
        }
    };

    const onAdd = () => {
        mode.current = "add";
        setData({
            RoleID: '',
            RoleName: '',
            Company: [],
            IsSpecifiedData: false,
            IsManager: false,
        });
        setChecked(false);
        setDisabled(false);
        setDisabledCompany(false);
        setIsUpdate(true);
    };

    const onEdit = () => {
        mode.current = "edit";
        props.w00f0010Actions.checkEditRoleW00F0010({RoleID: dataForm.RoleID}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && Number(data.Status) === 1) {
                let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                setDisabledCompany(true);
            }
            setDisabled(false);
            setIsUpdate(true);
        });
    };

    const onSave = () => {
        const {RoleID, RoleName, Company, IsSpecifiedData, IsManager} = dataForm;
        const param = {
            RoleID: RoleID,
            RoleName: RoleName,
            StrCompanyID: Company.join(","),
            Disabled: checked ? 1 : 0,
            IsSpecifiedData: IsSpecifiedData ? 1 : 0,
            IsManager: IsManager ? 1 : 0,
        };

        const validateRules = [
            {
                name: "RoleID",
                rules: ["isRequired"],
                value: RoleID
            },
            {
                name: "RoleName",
                rules: "isRequired",
                value: RoleName
            },
            {
                name: "Company",
                rules: "isRequired",
                value: Company.join(",")
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (setStateErrorText(validateForm)) return false; //Neu co loi validate

        const modeAPI = mode.current === "add" ? "addRoleW00F0010" : "editRoleW00F0010";
        props.w00f0010Actions[modeAPI](param, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                switch (errorCode) {
                    case "F0010E001":
                        message = Config.lang("Ma_vai_tro_bat_buoc_nhap");
                        break;
                    case "F0010E009":
                        message = Config.lang("Ma_vai_tro_da_ton_tai");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            loadRoleInfo();

            setIsUpdate(false);
            setDisabled(true);
            if (mode.current === "add" && listRolesRef) listRolesRef.updateRoleID(RoleID);
            Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
        });
    };

    const onNotSave = () => {

        let _Company = [];
        if (getRoleInfo && getRoleInfo.StrCompanyID) {
            const listCompany = getRoleInfo.StrCompanyID.replace(/,/g, ';').split(";");
            listCompany.forEach((e) => {
                _Company.push(e);
            });
        }
        setData({
            RoleID: !_.isEmpty(getRoleInfo) ? getRoleInfo.RoleID : "",
            RoleName: !_.isEmpty(getRoleInfo) ? getRoleInfo.RoleName : "",
            Company: _Company,
        });
        setDisabled(true);
        setDisabledCompany(false);
        setIsUpdate(false);
        setChecked(getRoleInfo ? !!getRoleInfo.Disabled : false);
        setStateErrorText(null);
    };

    const onEditPermission = () => {
        setShowEditPermission(true);
    };

    const onCloseModal = () => {
        setShowEditPermission(false);
    };

    const onRolePerSaved = () => {
        setShowEditPermission(false);
        loadGridPermissionInfo();
    };

    const onAddNewUserRole = () => {
        setIsEditing(true);
        setModeUserRoles("add");
        // this.setState({isEditing: true, modeUserRoles: "add"}, () => {
        //     setTimeout(() => {
        //         if (dataGridUserRole.current) {
        //             dataGridUserRole.current.instance.addRow();
        //         }
        //     }, 300);
        // });
    };

    const onEditUserRole = (e) => {
        eRow.current = e;
        setIsEditing(true);
        setModeUserRoles("edit");
    };

    const _saveUserRoles = (params, cb) => {
        props.w00f0010Actions.saveUserRolesW00F0010(params, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                if (errorCode === "F0010E011") {
                    message = Config.lang("Tham_so_truyen_vao_khong_hop_le");
                }
                Config.popup.show('INFO', message);
                return false;
            }
            else if (data?.Status === 1) {
                if(data?.MsgAsk === 0){
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                else if(data?.MsgAsk === 1){
                    Config.popup.show('INFO', "MsgAsk === 0: BA chưa có logic xử lý TH này");
                }
            }
            else if (data?.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
            }
            cb && cb();
        });
    };

    const onRowUpdated = (e) => {
        if (!e) return false;
        const {data} = e;
        data.ValidDateFrom =  data.ValidDateFrom ? moment(data.ValidDateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS') : null;
        data.ValidDateTo =  data.ValidDateTo ? moment(data.ValidDateTo).startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS') : null;
        if (dataForm.RoleID) {
            const TransID = data && data.TransID ? data.TransID : "";
            const params = {
                params: JSON.stringify([{
                    roleID: dataForm.RoleID,
                    transID: data && data.TransID ? data.TransID : "",
                    validDateFrom: data && data.ValidDateFrom ? data.ValidDateFrom : null,
                    validDateTo: data && data.ValidDateTo ? data.ValidDateTo : null,
                    disabled: data && data.Disabled ? data.Disabled : 0,
                    userID: data && data.UserID ? data.UserID : 0,
                    createDate: data && data.CreateDate ? data.CreateDate : null,
                    createUserID: data && data.CreateUserID ? data.CreateUserID : "",
                    lastModifyUserID: data && data.LastModifyUserID ? data.LastModifyUserID : "",
                    lastModifyDate: data && data.LastModifyDate ? data.LastModifyDate : null,
                }]),
                IsEdit: data?.IsEdit || 0,
            };
            _saveUserRoles(params, () => {
                if (!TransID) {
                    loadGridUsersInfo();
                }
            });
        } else {
            Config.popup.show('INFO', Config.lang("Khong_ton_tai_RoleID"));
        }
    };

    //End Event...
    ///////////////////////////////////////////

    //////////////////////////////////////////
    //Render...
    const renderDisabled = (e) => {
        return (
            <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"/>
        );
    };

    const renderButtons = (e) => {
        return (
            !e.row.isEditing ?
                <IconButton aria-label="view" className={"btn-grid-action mgr5"} size={"small"}
                            onClick={() => onEditUserRole(e)}>
                    <EditIcon fontSize="small"/>
                </IconButton>
                :
                <>
                    <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                onClick={() => e.component.saveEditData()}>
                        <SaveIcon fontSize="small"/>
                    </IconButton>
                    <IconButton aria-label="view" className={"btn-grid-action mgr5"}
                                onClick={() => e.component.cancelEditData()}>
                        <UndoIcon fontSize="small"/>
                    </IconButton>
                </>
        );
    };

    const setFocusFilterInfo = (timeout = 1000) => {
        setTimeout(() => {
            if(filterColIndx.current > -1) {
                let el = document.getElementById('permissionInfo');
                let containerFilter = el.getElementsByClassName("dx-datagrid-filter-row");
                containerFilter     = containerFilter && containerFilter.length > 0 ? containerFilter[0] : null;
                if (containerFilter) {
                    const cellFilter = containerFilter.querySelector("td:nth-child(" + (filterColIndx.current + 1) + ")");
                    if (cellFilter) cellFilter.getElementsByTagName("input")[0].focus();
                }
                filterColIndx.current = -1;
            }
        },timeout)
    };

    const setFocusFilterUserRole = (timeout = 1000) => {
        setTimeout(() => {
            if(filterColIndx.current > -1) {
                let el = document.getElementById('dataGridUserRole');
                let containerFilter = el.getElementsByClassName("dx-datagrid-scroll-container");
                containerFilter     = containerFilter && containerFilter.length > 0 ? containerFilter[1] : null;
                if (containerFilter) {
                    let containerTableFilter = containerFilter.getElementsByClassName("dx-datagrid-filter-row");
                    containerTableFilter = containerTableFilter && containerTableFilter.length > 0 ? containerTableFilter[0] : null;
                    if(containerTableFilter) {
                        const cellFilter = containerTableFilter.querySelector("td:nth-child(" + (filterColIndx.current + 1) + ")");
                        if (cellFilter) cellFilter.getElementsByTagName("input")[0].focus();
                    }
                }
                filterColIndx.current = -1;
            }
        },timeout)
    };
    //End render..
    ////////////////////////////////////////

    const errorRoleID = !!errorHelperText['RoleID'] && errorHelperText['RoleID'].length > 0;
    const errorRoleName = !!errorHelperText['RoleName'] && errorHelperText['RoleName'].length > 0;
    const dataCboUserRoles = getCboUserRoles && getCboUserRoles.rows ? getCboUserRoles.rows : [];

    return (
        <div>
            <Modal open={showEditPermission} maxWidth={"lg"} fullWidth={true}
                   onClose={onCloseModal}
                   title={Config.lang("Phan_quyen_theo_vai_tro")}
                   scroll={"paper"}
                   component={
                       <W00F0010RolePermission RoleID={dataForm.RoleID} onSaved={onRolePerSaved} />
                   }
                />
            <Card className={classes.root}>
                <CardContent>
                    <FormGroup>
                        <Row className={'row-header'}>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Label>
                                    <span className={"card-header"}>{Config.lang('Thong_tin_vai_tro')}</span>
                                </Label>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                {isUpdate && <ButtonGeneral name={Config.lang('Khong_luu')}
                                                            typeButton={"notsave"}
                                                            size={"medium"}
                                                            style={{ textTransform: 'uppercase' }}
                                                            className={'pull-right'}
                                                            onClick={onNotSave} />}
                                {isUpdate && <ButtonGeneral name={Config.lang('Luu')}
                                                            style={{textTransform: "uppercase"}}
                                                            typeButton={'save'}
                                                            size={"medium"}
                                                            className={'pull-right mgr5'}
                                                            onClick={onSave}/>}
                                {!isUpdate && dataForm.RoleID && <ButtonGeneral name={Config.lang('Sua')}
                                                          typeButton={'edit'}
                                                          className={'pull-right'}
                                                          style={{ textTransform: 'uppercase' }}
                                                          size={"medium"}
                                                          onClick={onEdit} />}
                                {!isUpdate && <ButtonGeneral name={Config.lang('Them_moi1')}
                                                         typeButton={'add'}
                                                         size={"medium"}
                                                         style={{ textTransform: 'uppercase' }}
                                                         className={'pull-right mgr5'}
                                                         onClick={onAdd} />}
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"row-form mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <Row>
                                    <Col xs={12} sm={5} md={5} lg={5}>
                                        {loading.main ? (
                                            <Skeleton variant="rect" className={'skeleton-form'}/>
                                        ) : (
                                            <TextField
                                                error={errorRoleID}
                                                disabled={disabled || mode.current === 'edit'}
                                                label={Config.lang('Ma_vai_tro')}
                                                variant={"outlined"}
                                                placeholder={Config.lang('Ma_vai_tro')}
                                                name={'RoleID'}
                                                value={dataForm.RoleID}
                                                onChange={(e) => handleChange('RoleID', e)}
                                                fullWidth
                                                required
                                                helperText={errorHelperText['RoleID'] && errorHelperText['RoleID']}
                                            />
                                        )}
                                    </Col>
                                    <Col xs={12} sm={7} md={7} lg={7}>
                                        {loading.main ? (
                                            <Skeleton variant="rect" className={'skeleton-form'}/>
                                        ) : (
                                            <TextField
                                                error={errorRoleName}
                                                disabled={disabled}
                                                label={Config.lang('Ten_vai_tro')}
                                                variant={"outlined"}
                                                placeholder={Config.lang('Ten_vai_tro')}
                                                name={"RoleName"}
                                                value={dataForm.RoleName}
                                                onChange={(e) => handleChange('RoleName', e)}
                                                fullWidth
                                                required
                                                helperText={errorHelperText['RoleName'] && errorHelperText['RoleName']}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                {loading.main ? (
                                    <Skeleton variant="rect" className={'skeleton-form'}/>
                                ) : (
                                    <Combo
                                        error={!!errorHelperText['Company'] && errorHelperText['Company']}
                                        disabled={disabled || disabledCompany}
                                        dataSource={getCompany}
                                        displayExpr={'DatabaseName'}
                                        valueExpr={'DatabaseName'}
                                        label={Config.lang('Doanh_nghiep')}
                                        value={dataForm.Company}
                                        multiple={true}
                                        onValueChanged={(e) => handleChange("Company", e)}
                                    />
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>

                                <Checkbox
                                    disabled={disabled}
                                    checked={dataForm.IsManager}
                                    className={"no-margin pull-right"}
                                    value={true}
                                    label={Config.lang('Quan_ly')}
                                    required
                                    labelProps={{
                                        classes: {label: classes.labelCheckbox}
                                    }}
                                    controlProps={{
                                        classes: {root: classes.checkbox}
                                    }}
                                    color={"primary"}
                                    onChange={(e, data) => handleChange('IsManager', e, data)}
                                />
                                <Checkbox
                                    disabled={disabled}
                                    checked={dataForm.IsSpecifiedData}
                                    className={"no-margin pull-right"}
                                    value={true}
                                    label={Config.lang('Du_lieu_dac_biet')}
                                    required
                                    labelProps={{
                                        classes: {label: classes.labelCheckbox}
                                    }}
                                    controlProps={{
                                        classes: {root: classes.checkbox}
                                    }}
                                    color={"primary"}
                                    onChange={(e, data) => handleChange('IsSpecifiedData', e, data)}
                                />
                                <Checkbox
                                    disabled={disabled}
                                    checked={checked}
                                    className={"no-margin pull-right"}
                                    value={true}
                                    label={Config.lang('Khong_su_dung')}
                                    required
                                    labelProps={{
                                        classes: {label: classes.labelCheckbox}
                                    }}
                                    controlProps={{
                                        classes: {root: classes.checkbox}
                                    }}
                                    color={"primary"}
                                    onChange={(e, data) => handleChange('KSD', e, data)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={8} md={8} lg={8}>
                                <Tabs
                                    value={tabKey}
                                    onChange={(e, value) => handleChange("Tab", e, value)}
                                    indicatorColor="primary"
                                    // textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="off"
                                    TabIndicatorProps={{style: {display: 'none'}}}
                                    style={{fontSize: '16px'}}
                                    aria-label="full width tabs"
                                >
                                        <Tab classes={{root: classes.tab, selected: classes.tabActive}} label={Config.lang("Thong_tin_phan_quyen")}/>
                                        <Tab classes={{root: classes.tab, selected: classes.tabActive}} label={Config.lang("Thong_tin_nguoi_dung")}/>
                                </Tabs>
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={4}>
                                <div className={"pull-right mgt5"} style={{backgroundColor: "#fff"}}>
                                {tabKey === 0 && dataForm.RoleID &&
                                        <ButtonGeneral typeButton={'edit'}
                                                       size={"medium"}
                                                       color={"default"}
                                                       style={{backgroundColor: "#fff"}}
                                                       variant={"contained"}
                                                       onClick={onEditPermission}/>
                                }
                                {tabKey === 1 && dataForm.RoleID &&
                                        <ButtonGeneral typeButton={'add'}
                                                       disabled={isEditing}
                                                       color={"default"}
                                                       style={{backgroundColor: "#fff"}}
                                                       variant={"contained"}
                                                       size={"medium"}
                                                       onClick={onAddNewUserRole}/>
                                }
                                </div>
                            </Col>
                        </Row>
                        <TabContent
                            style={{padding: "15px", backgroundColor: '#fff'}}
                            activeKey={tabKey}
                            disableSwipe={true}
                            lazyLoading={true}
                        >
                            <TabPanel index={0}>
                                <React.Fragment>
                                        <Label>{Config.lang("Thong_tin_phan_quyen")}</Label>
                                    <GridContainer
                                        reference={ref => permissionInfo.current = ref}
                                        // totalItems={dataPermissionInfo && dataPermissionInfo.length}
                                        itemPerPage={filterPermission.current.limit}
                                        skipPerPage={filterPermission.current.skip}
                                        dataSource={dataPermissionInfo}
                                        columnAutoWidth={true}
                                        loadPanel={{
                                            visible: loading.gridPermission
                                        }}
                                        filterRow={{
                                            visible: true,
                                            showOperationChooser: false,
                                        }}
                                        elementAttr={{
                                            id: "permissionInfo"
                                        }}
                                        onFilterGrid={(e) => {
                                            const {columnIndex} = e;
                                            filterColIndx.current = columnIndex;
                                        }}
                                        onContentReady={() => {
                                            setFocusFilterInfo(500);
                                        }}
                                        typePaging={"normal"}
                                        height={500}
                                        typeShort={true}
                                        // onChangePage={onChangePagePermission}
                                        // onChangePerPage={onChangePerPagePermission}
                                    >
                                        <Column
                                            caption={Config.lang("Module")}
                                            dataField={'ModuleID'}
                                            width={120}
                                        />
                                        <Column
                                                caption={Config.lang("Man_hinh")}
                                            dataField={'ScreenID'}
                                            alignment={"center"}
                                            width={150}
                                        />
                                        <Column
                                                caption={Config.lang("Ten_man_hinh")}
                                            dataField={'ScreenName'}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'IsPermission'}
                                            visible={false}
                                        />
                                        <Column
                                                caption={Config.lang("Quyen_han")}
                                            dataField={'PermissionName'}
                                            alignment={"center"}
                                            width={150}
                                        />
                                        <Column
                                                caption={Config.lang("Co_cau_to_chuc")}
                                            dataField={'OrgChartID'}
                                        />
                                        <Column
                                                caption={Config.lang("Du_an")}
                                            dataField={'ProjectID'}
                                            width={130}
                                        />
                                        <Column
                                                caption={Config.lang("Nhom_luong")}
                                            dataField={'SalEmpGroupID'}
                                            width={130}
                                        />
                                    </GridContainer>
                                </React.Fragment>
                            </TabPanel>
                            <TabPanel index={1}>
                                <React.Fragment>
                                        <Label>{Config.lang("Thong_tin_nguoi_dung")}</Label>
                                    <GridContainer
                                        reference={ref => dataGridUserRole.current = ref}
                                        dataSource={dataUsersInfo}
                                        columnAutoWidth={true}
                                        loadPanel={{
                                            enabled: loading.gridUser
                                        }}
                                        height={500}
                                        editing={{
                                            mode: 'row',
                                            refreshMode: "reshape",
                                            // allowAdding: true,
                                            // allowUpdating: true,
                                        }}
                                        filterRow={{
                                            visible: true,
                                            showOperationChooser: false,
                                        }}
                                        elementAttr={{
                                            id: "dataGridUserRole"
                                        }}
                                        onFilterGrid={(e) => {
                                            const {columnIndex} = e;
                                            filterColIndx.current = columnIndex;
                                        }}
                                        typePaging={"normal"}
                                        hoverStateEnabled={true}
                                        typeShort={true}
                                        onContentReady={(e) => {
                                            setFocusFilterUserRole(500);
                                            if (isEditing) {
                                                setTimeout(() => {
                                                    const rows = e.component.getVisibleRows();
                                                    const editRows = rows.filter(r => r.isEditing);
                                                    if (editRows.length < 1) {
                                                        setIsEditing(false);
                                                    }
                                                }, 400);
                                            }
                                        }}
                                        onRowInserted={onRowUpdated}
                                        onRowUpdated={onRowUpdated}
                                    >
                                        <Column type="buttons" width={90}
                                                // buttons={['edit']}
                                                cellRender={renderButtons}
                                                fixed={true}
                                                fixedPosition={"left"}
                                        />
                                            <Column
                                              dataField={'IsEdit'}
                                              visible={false}
                                            />
                                        <Column
                                                caption={Config.lang("Ma_nguoi_dung")}
                                            dataField={'UserID'}
                                            width={150}
                                            allowEditing={modeUserRoles === "add"}
                                            editCellRender={(e) => {
                                                const selectedItem = dataCboUserRoles.filter((c) => {
                                                    return c.UserID === e.value;
                                                });
                                                return (
                                                    <Combo
                                                        dataSource={Config.storeDataSoureDevExtreme(dataCboUserRoles)}
                                                        displayExpr={'UserID'}
                                                        stylingMode={'underlined'}
                                                        margin={"normal"}
                                                        className={"no-margin"}
                                                        style={{backgroundColor: "transparent"}}
                                                        shrink={true}
                                                            placeholder={Config.lang("Chon")}
                                                        value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                        onValueChanged={(combo) => {
                                                            e.setValue(combo.value && combo.value.UserID ? combo.value.UserID : "");
                                                            e.component.cellValue(e.rowIndex, "UserName", combo.value && combo.value.UserName ? combo.value.UserName : "");
                                                            e.component.cellValue(e.rowIndex, "UserDepartment", combo.value && combo.value.UserDepartment ? combo.value.UserDepartment : "");
                                                        }}
                                                    />
                                                );
                                            }}
                                        >
                                            <RequiredRule message="Giá trị bắt buộc nhập"/>
                                        </Column>
                                        <Column
                                                caption={Config.lang("Ten_nguoi_dung")}
                                            dataField={'UserName'}
                                            width={150}
                                            allowEditing={false}
                                        />
                                        <Column
                                                caption={Config.lang("Ten_phong_ban")}
                                            dataField={'UserDepartment'}
                                            width={180}
                                            allowEditing={false}
                                        />
                                        <Column
                                                caption={Config.lang("Ngay_hieu_luc_tu")}
                                            dataField={'ValidDateFrom'}
                                            dataType={'date'}
                                            format={'dd/MM/yyyy'}
                                            alignment={'center'}
                                            width={150}
                                            editorOptions={{
                                                placeholder: "DD/MM/YYYY"
                                            }}
                                        />
                                        <Column
                                                caption={Config.lang("Ngay_hieu_luc_den")}
                                            dataField={'ValidDateTo'}
                                            dataType={'date'}
                                            format={'dd/MM/yyyy'}
                                            alignment={'center'}
                                            editorOptions={{
                                                placeholder: "DD/MM/YYYY"
                                            }}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("KSD")}
                                            dataField={'Disabled'}
                                            alignment={'center'}
                                            width={150}
                                            cellRender={renderDisabled}
                                            editCellRender={(e) => {
                                                return <CheckboxM classes={{root: classes.checkbox}} label={""} checked={!!e.value} color="primary"
                                                    onChange={(chk) => {
                                                        e.component.cellValue(e.rowIndex, "Disabled", chk.target.checked ? 1 : 0);
                                                    }}
                                                />
                                            }}
                                        />
                                    </GridContainer>
                                </React.Fragment>
                            </TabPanel>
                        </TabContent>
                    </FormGroup>
                </CardContent>
            </Card>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.roleID === nextProps.roleID;
}));

W00F0010RoleInfo.propTypes = {
    roleID: PropTypes.string,

    listRolesRef: PropTypes.any
};

export default compose(connect((state) => ({
        getCompany: state.W00F0010.getCompany,
        getRoleInfo: state.W00F0010.getRoleInfo,
        getCboUserRoles: state.W00F0010.getCboUserRoles
    }),
    (dispatch) => ({
        w00f0010Actions: bindActionCreators(w00f0010Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
}), null, {forwardRef: true})
)(W00F0010RoleInfo);
